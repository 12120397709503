import { QueryClient, QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getScoreTypes, postScoreType } from './score-type-api';
import { ScoreType } from '../@types';
import { message } from 'antd';
import { useCallback } from 'react';

export const scoreTypeKeys = {
  all: (): QueryKey => ['ScoreType'],
  detail: (id: string): QueryKey => [...scoreTypeKeys.all(), id],
};

export const scoreTypeQuery: UseQueryOptions<ScoreType[]> = { queryKey: scoreTypeKeys.all(), queryFn: ({ signal }) => getScoreTypes(signal) };

export const scoreTypeMutation = (queryClient: QueryClient, id: string): UseMutationOptions<ScoreType, any, ScoreType, any> => ({
  mutationKey: scoreTypeKeys.detail(id),
  mutationFn: scoreType => postScoreType(id, { ...scoreType }),
  onMutate: async scoreType => {
    // Verwijderen van vorige mutations die in de cache zitten, met dezelfde key en nog niet uitgevoerd zijn. We willen alleen de laatste uitvoeren.
    // Onderstaande lijkt niet mogelijk
    // const mutationCache = queryClient.getMutationCache();
    // const sameKeyMutations = queryClient.getMutationCache().findAll({
    //   mutationKey: scoreTypeKeys.detail(id),
    //   exact: true,
    //   fetching: true,
    // });
    // if (sameKeyMutations.length > 1) {
    //   const highestId = Math.max(...sameKeyMutations.map(mutation => mutation.mutationId));

    //   sameKeyMutations.forEach(mutation => {
    //     if (mutation.mutationId !== highestId) {
    //       mutationCache.remove(mutation);
    //     }
    //   });
    // }

    // Cancel current queries for the user
    await queryClient.cancelQueries({ queryKey: scoreTypeKeys.detail(id) });
    const previousData = queryClient.getQueryData(scoreTypeKeys.detail(id));

    // Optimistic edit of the user
    queryClient.setQueryData(scoreTypeKeys.detail(id), () => scoreType);

    // message.info('ScoreType is alvast lokaal opgeslagen.');

    // Return context with the optimistic todo
    return { optimisticData: scoreType, previousData };
  },
  onSuccess: (result, _scoreType, _context) => {
    // Replace optimistic user with the actual result (todo: if result is precisely the same as context.optimisticData, then don't replace and don't trigger rerender)
    // queryClient.setQueryData(scoreTypeKeys.detail(id), () => result);
    message.success('ScoreType daadwerkelijk online opgeslagen');
    console.log('success');
  },
  onError: (error, _scoreType, context) => {
    console.error(error);
    message.error(error);
    // Reset scoreType to previousData
    queryClient.setQueryData(scoreTypeKeys.detail(id), () => context.previousData);
  },
  onSettled: () => {
    console.log('settled.');
    // We don't need to invalidate the query because we used the result in the onSucces.
    // Uncomment below if you do want to refetch though.
    queryClient.invalidateQueries({ queryKey: scoreTypeKeys.detail(id) });
  },
});

// This function is callable from anywhere and it enables you to already have the score type available in cache
export const prefetchScoreType = async (queryClient: QueryClient): Promise<void> => {
  await queryClient.prefetchQuery(scoreTypeQuery);
};

export const useScoreTypes = () => {
  const query = useQuery(scoreTypeQuery);
  return { query };
};

export const PostScoreTypeSettings = () => {
  const queryClient = useQueryClient();
  const update = useMutation(scoreTypeMutation(queryClient, ''));
  const mutate: typeof update.mutate = useCallback((variables, options) => update.mutate({ ...variables }, options), [update]);
  return { update: { ...update, mutate } };
};
