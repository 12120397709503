import { QueryClient, MutationCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { PersistQueryClientProvider, removeOldestQuery } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { message } from 'antd';
import { decompress } from 'lz-string';
import { setMutationDefaults as animalScoreSetMutationDefaults } from '@/modules/score-app/model/animal-score-queries';
import { Suspense, lazy, useEffect, useState } from 'react';

const HALF_HOUR = 1000 * 60 * 60 * 0.5;
const MAX_RETRY = 2;
const MAX_EXPONENTIAL_RETRY_DELAY_OF_ONE_HOUR = 1000 * 60 * 60 * 1;

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
  retry: removeOldestQuery,
  serialize: data => JSON.stringify(data),
  deserialize: data => {
    try {
      return JSON.parse(data);
    } catch (jsonParseError) {
      // If parsing fails, the data might be and old compressed version of the data
      try {
        const decompressedData = decompress(data);
        return JSON.parse(decompressedData);
      } catch (decompressError) {
        // Handle decompression failure
        console.error('Failed to decompress and parse the data:', decompressError);
        throw decompressError;
      }
    }
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: HALF_HOUR,
      staleTime: HALF_HOUR,
      retry: failureCount => failureCount < MAX_RETRY,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, MAX_EXPONENTIAL_RETRY_DELAY_OF_ONE_HOUR),
    },
    mutations: {
      cacheTime: Infinity,
      retry: failureCount => failureCount < MAX_RETRY,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, MAX_EXPONENTIAL_RETRY_DELAY_OF_ONE_HOUR),
    },
  },
  // configure global cache callbacks to show toast notifications
  mutationCache: new MutationCache({
    onSuccess: (data: any) => {
      if (data?.message) message.success(data.message);
    },
    onError: (error: any) => {
      if (error?.message) message.error(error.message);
    },
  }),
});

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(d => ({
    default: d.ReactQueryDevtools,
  }))
);

animalScoreSetMutationDefaults(queryClient);

export const PersistingQueryClientProvider = ({ children }) => {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools(old => !old);
  }, []);

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={() => {
        queryClient.resumePausedMutations().then(async () => {
          // TODO: Check if we really need to invalidate queries here
          await queryClient.invalidateQueries();
        });
      }}>
      {children}
      <ReactQueryDevtools position="bottom-right" />
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction position="bottom-right" />
        </Suspense>
      )}
    </PersistQueryClientProvider>
  );
};
