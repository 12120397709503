import { QueryClient, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Location } from '../@types';
import { getLocations } from './location-api';

export const locationKeys = {
  all: (): QueryKey => ['Location'],
};

export const locationQuery: UseQueryOptions<Location[]> = { queryKey: locationKeys.all(), queryFn: ({ signal }) => getLocations(signal) };

// This function is callable from anywhere and it enables you to already have the cow available in cache
export const prefetchLocations = async (queryClient: QueryClient): Promise<void> => {
  await queryClient.prefetchQuery(locationQuery);
};

export const useLocations = () => {
  const query = useQuery(locationQuery);

  return { query };
};
