import { FC } from 'react';
import { Switch, InputNumber, Form, Table, Tooltip, Grid } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThanEqual, faLessThanEqual, faEquals, faGreaterThan, faLessThan } from '@fortawesome/pro-solid-svg-icons';
import { ColumnType } from 'antd/es/table';
import styles from './styles.module.scss';
import { Parameter, ScoreGroupSetting } from '../../@types';
import { useParams } from 'react-router-dom';
const { useBreakpoint } = Grid;

const ParameterTable: FC<{ scoreGroupSetting: ScoreGroupSetting }> = ({ scoreGroupSetting }) => {
  const { location_id } = useParams<{ score_group_id: string; location_id: string }>();
  const breakpoints = useBreakpoint();

  const paramColumns: ColumnType<Parameter>[] = [
    { title: 'Naam', dataIndex: 'name', key: 'name', width: '25%' },
    {
      title: 'Wanneer',
      dataIndex: 'operator',
      key: 'operator',
      width: '25%',
      render: (_, scoreGroupParameterSetting: Parameter) => {
        if (scoreGroupParameterSetting.operator === '=') {
          return (
            <Tooltip title="Gelijk aan">
              <FontAwesomeIcon icon={faEquals} />
            </Tooltip>
          );
        } else if (scoreGroupParameterSetting.operator === '>') {
          return (
            <Tooltip title="Groter dan">
              <FontAwesomeIcon icon={faGreaterThan} />
            </Tooltip>
          );
        } else if (scoreGroupParameterSetting.operator === '<') {
          return (
            <Tooltip title="Kleiner dan">
              <FontAwesomeIcon icon={faLessThan} />
            </Tooltip>
          );
        } else if (scoreGroupParameterSetting.operator === '≥') {
          return (
            <Tooltip title="Groter dan of gelijk aan">
              <FontAwesomeIcon icon={faGreaterThanEqual} />
            </Tooltip>
          );
        } else if (scoreGroupParameterSetting.operator === '≤') {
          return (
            <Tooltip title="Kleiner dan of gelijk aan">
              <FontAwesomeIcon icon={faLessThanEqual} />
            </Tooltip>
          );
        }
        return null;
      },
    },
    ...(!breakpoints.xs
      ? [
          {
            title: 'Basis',
            dataIndex: 'baseValue',
            key: 'baseValue',
            width: '25%',
            render: (_, scoreGroupParameterSetting: Parameter) => (
              <span>
                {scoreGroupParameterSetting.baseValue?.toString() === 'true'
                  ? 'Waar'
                  : scoreGroupParameterSetting.baseValue?.toString() === 'false'
                  ? 'Onwaar'
                  : scoreGroupParameterSetting.baseValue}
              </span>
            ),
          },
        ]
      : []),
    ...(location_id
      ? [
          {
            title: 'Aangepast',
            dataIndex: 'id',
            key: 'type',
            width: '25%',
            render: (_, scoreGroupParameterSetting: Parameter, index: number) => {
              // Determine initial value for the Form.Item based on baseValue
              // Extract locationValue if available
              const locationValue = scoreGroupParameterSetting.locationSpecificParamaters[0]?.value;

              // Determine initialValue based on locationValue or fallback to baseValue
              const initialValue =
                locationValue !== undefined
                  ? ['true', 'false'].includes(locationValue.toString())
                    ? locationValue.toString() === 'true'
                    : Number(locationValue)
                  : scoreGroupParameterSetting.baseValue !== null
                  ? ['true', 'false'].includes(scoreGroupParameterSetting.baseValue?.toString() || '')
                    ? scoreGroupParameterSetting.baseValue?.toString() === 'true'
                    : Number(scoreGroupParameterSetting.baseValue)
                  : undefined;

              return (
                <>
                  <Form.Item hidden name={['locationScoreGroupParameters', index, 'parameterId']} initialValue={scoreGroupParameterSetting?.id} />
                  <Form.Item
                    initialValue={initialValue}
                    valuePropName={['true', 'false'].includes(scoreGroupParameterSetting.baseValue?.toString() || '') ? 'checked' : 'value'}
                    name={['locationScoreGroupParameters', index, 'value']}
                    className={styles.paramContainer}>
                    {['true', 'false'].includes(scoreGroupParameterSetting.baseValue?.toString() || '') ? (
                      <Switch className={styles.switch} />
                    ) : (
                      <InputNumber style={{ width: '100%' }} min={scoreGroupParameterSetting.min} max={scoreGroupParameterSetting.max} />
                    )}
                  </Form.Item>
                </>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <div className={styles.customStyle}>
      <Table
        dataSource={scoreGroupSetting.scoreGroupParameters}
        pagination={false}
        style={{ width: '100%' }}
        columns={paramColumns}
        rowKey={record => record.id}
      />
    </div>
  );
};

export default ParameterTable;
