import { FC, useCallback, useState, useEffect } from 'react';
import { Collapse } from 'antd';
import { useCategories } from '../../model/categories-queries';
import { Link, useParams } from 'react-router-dom';
import styles from './styles.module.scss';

interface SideMenuAccordionProps {
  isCollapseOpen: boolean;
}

const SideMenuAccordion: FC<SideMenuAccordionProps> = ({ isCollapseOpen }) => {
  const { query: categoriesQuery } = useCategories();
  const { category_id, score_group_id, location_id } = useParams<{ category_id: string; score_group_id: string; location_id: string }>();
  const [activeKey, setActiveKey] = useState<string | string[] | undefined>();

  useEffect(() => {
    if (category_id) {
      setActiveKey([category_id]);
    }
  }, [category_id]);

  const onChange = useCallback((newActiveKey: string | string[]) => {
    setActiveKey(newActiveKey);
  }, []);

  return (
    <div className={styles.customCollapse}>
      <Collapse activeKey={activeKey} accordion onChange={onChange} style={{ width: isCollapseOpen ? '22em' : '5em' }}>
        {categoriesQuery.data
          ? categoriesQuery.data.map((item, index) => (
              <Collapse.Panel
                key={item.id}
                header={
                  <span>
                    {item.code} {isCollapseOpen && item.name}
                  </span>
                }>
                {item.scoreGroups?.map((subItem, subIndex) => (
                  <Link
                    to={
                      location_id
                        ? `/instellingen/${location_id}/scoregroup-settings/${item?.id}/score-group/${subItem?.id}`
                        : `/instellingen/scoregroup-settings/${item?.id}/score-group/${subItem?.id}`
                    }
                    key={`submenu-${index}-${subIndex}`}>
                    <div
                      style={
                        subItem.id === score_group_id
                          ? {
                              backgroundColor: '#585757',
                              borderLeft: '6px solid #622567',
                            }
                          : undefined
                      }>
                      <span>
                        {subItem.code} {isCollapseOpen && subItem.name}
                      </span>
                    </div>
                  </Link>
                ))}
              </Collapse.Panel>
            ))
          : null}
      </Collapse>
    </div>
  );
};

export default SideMenuAccordion;
