import { Animal, AnimalScore } from '@/modules/score-type/@types';
import { isNotNullOrUndefined } from '@/shared/utilities';
import { QueryClient, QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useCallback } from 'react';
import { PreviousScore } from '../@types/PreviousScore';
import { formatDate } from '../components/generate-animal-form/utilts';
import { animalKeys } from './animal-queries';
import { getAnimalScore, postAnimalScore } from './animal-score-api';

export const animalScoreKeys = {
  all: (): QueryKey => ['Score'],
  detail: (id: string): QueryKey => [...animalScoreKeys.all(), id],
};

export const animalScoreQuery = (id: string): UseQueryOptions<AnimalScore> => ({
  queryKey: animalScoreKeys.detail(id),
  queryFn: ({ signal }) => getAnimalScore(id, signal),
});

const saveFailedMutation = animalScore => {
  const scoreBuffer = JSON.parse(localStorage.getItem('Score_Buffer')) || [];
  scoreBuffer.push(animalScore);
  localStorage.setItem('Score_Buffer', JSON.stringify(scoreBuffer));
};

export const retryFailedMutations = async () => {
  let scoreBuffer = JSON.parse(localStorage.getItem('Score_Buffer')) || [];

  for (const item of scoreBuffer) {
    try {
      await postAnimalScore(item._id, item);

      // If successful, remove the mutation from localStorage
      scoreBuffer = scoreBuffer.filter(m => m !== item);
      localStorage.setItem('Score_Buffer', JSON.stringify(scoreBuffer));
    } catch (error) {
      console.error('Retry failed. animalScore left in localStorage for future attempt.', error);
    }
  }
};

export const animalScoreMutation = (queryClient: QueryClient, id: string): UseMutationOptions<AnimalScore, any, AnimalScore, any> => ({
  mutationKey: animalScoreKeys.detail(id),
  mutationFn: async animalScore => {
    return postAnimalScore(id, { ...animalScore, _id: undefined });
  },
  onMutate: async animalScore => {
    // Cancel current queries for the user
    await queryClient.cancelQueries({ queryKey: animalScoreKeys.detail(id) });
    const previousData = queryClient.getQueryData(animalScoreKeys.detail(id));

    // Optimistic edit of the user
    queryClient.setQueryData(animalScoreKeys.detail(id), () => animalScore);

    // This is tricky: like the animalScore, you would like to reset the optimistic edit on Error.
    // But because this is a different state object, with a different API call context, it might get changed outside this context
    // Reverting it to the previousState onError of this update can make the state inconsistent
    updateLinkedAnimalState(queryClient, animalScore);

    // Return context with the optimistic todo
    return { optimisticData: animalScore, previousData };
  },
  onSuccess: (result, animalScore, _context) => {
    queryClient.setQueryData(animalScoreKeys.detail(id), () => animalScore);

    updateLinkedAnimalState(queryClient, animalScore);
    message.success('Score daadwerkelijk online opgeslagen');

    retryFailedMutations();
  },
  onError: (error, animalScore, context) => {
    message.error(error);
    saveFailedMutation(animalScore);

    // Reset cowScore to previousData
    queryClient.setQueryData(animalScoreKeys.detail(id), () => context.previousData);
  },
});

const updateLinkedAnimalState = (queryClient: QueryClient, animalScore: AnimalScore) => {
  // This function updates the state of the animal with the new scores
  // Note that it would be better if the load of the scores used the same query key, so you could simply update /Scores/AnimalId as show above
  // But the scores page is using the selectedCow data, instead of a getScores query, which makes it more complex
  // as we have to update the animal itself
  queryClient.setQueryData(animalKeys.detail(animalScore.locationId), (cv?: Animal[]) => {
    if (!cv) {
      return undefined;
    }
    try {
      const animalIndex = cv.findIndex(x => x.id === animalScore.animalId);
      if (animalIndex >= 0) {
        const currentDateString = formatDate(animalScore.dateTimeUtc);
        const animal = cv[animalIndex];

        // Update all previous scores by removing existing with same date and create from score
        const newScores: PreviousScore[] = animalScore.scoreValues
          .filter(x => isNotNullOrUndefined(x.value))
          .map(x => ({
            animalId: animalScore.animalId,
            dateTimeUtc: animalScore.dateTimeUtc,
            id: '',
            locationId: animalScore.locationId,
            scoreScoreGroups: [],
            scoreTypeId: x.scoreTypeId,
            scoredBy: '',
            treatmentDateTimeUtc: x.treatmentDateTimeUtc,
            value: x.value,
          }));
        const existingScores = animal.previousScores.filter(x => formatDate(x.dateTimeUtc) !== currentDateString);

        animal.previousScores = [...existingScores, ...newScores];
        cv[animalIndex] = animal;
      }
      return [...cv];
    } catch (error) {
      console.log(error, 'Error while updating the animal state');
    }
  });
};

// This function is callable from anywhere and it enables you to already have the user available in cache
export const prefetchAnimalScore = async (queryClient: QueryClient, id: string): Promise<void> => {
  await queryClient.prefetchQuery(animalScoreQuery(id));
};

// NOTE: Make sure this is called in PersistingQueryClientProvider
// See also: https://tanstack.com/query/v4/docs/react/guides/mutations#persisting-offline-mutations
export const setMutationDefaults = (queryClient: QueryClient) => {
  queryClient.setMutationDefaults(animalScoreKeys.all(), {
    mutationFn: async ({ _id: id, ...animalScore }) => {
      // To avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({ queryKey: animalScoreQuery(id).queryKey });
      // Run the actual mutation function
      return animalScoreMutation(queryClient, id).mutationFn?.(animalScore);
    },
  });
};

export const useAnimalScore = (id: string) => {
  const queryClient = useQueryClient();
  // const query = useQuery(animalScoreQuery(id)); // Backend for this doesn't exist (yet)
  const update = useMutation(animalScoreMutation(queryClient, id));

  // To override the default, because we need the id
  const mutate: typeof update.mutate = useCallback((variables, options) => update.mutate({ _id: id, ...variables }, options), [id, update]);

  return { update: { ...update, mutate } };
};
