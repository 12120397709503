import { useCallback } from 'react';
import { useServiceWorker } from '../../hooks/use-service-worker';
import styles from './styles.module.scss';

export const UpdateAvailabillity = () => {
  const { needRefresh, updateServiceWorker } = useServiceWorker();
  const updateSW = useCallback(() => updateServiceWorker(true), [updateServiceWorker]);

  return needRefresh ? (
    <div className={styles.updateContainer} onClick={updateSW}>
      <span>Er is een nieuwe versie beschikbaar van de applicatie, klik hier om te updaten</span>
      <button className="ReloadPrompt-toast-button">Reload</button>
    </div>
  ) : null;
};
