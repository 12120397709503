import { FC } from 'react';
import styles from './styles.module.scss';
import { SettingsForm } from '../components/settings-form';

const Settings: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <SettingsForm />
      </div>
    </div>
  );
};

export default Settings;
