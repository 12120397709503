import { QueryClient, QueryKey, UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ClientPrincipalData } from '../@types';
import { getUserInfo } from './api';
import { MOCK_USER_INFO } from '../mock/mock';

export const userKeys = {
  all: (): QueryKey => ['userInfo'],
};

export const userInfoQuery = (): UseQueryOptions<ClientPrincipalData> => ({
  queryKey: userKeys.all(),
  queryFn: ({ signal }) => (!import.meta.env.DEV ? getUserInfo(signal) : new Promise(resolve => resolve(MOCK_USER_INFO))),
  // cacheTime: 0, // Maybe this needs to be set higher, but what if cachetime is not over and user is no longer authenticated?
});

// This function is callable from anywhere and it enables you to already have the user info available in cache
export const prefetchUserInfo = async (queryClient: QueryClient): Promise<void> => {
  await queryClient.prefetchQuery(userInfoQuery());
};

export const useUserInfo = () => {
  const query = useQuery(userInfoQuery());

  const isFarmer = useMemo(() => query.data?.clientPrincipal?.userRoles.includes('farmer'), [query.data?.clientPrincipal?.userRoles]);
  const isVeterinary = useMemo(() => query.data?.clientPrincipal?.userRoles.includes('veterinarian'), [query.data?.clientPrincipal?.userRoles]);
  const isManager = useMemo(() => query.data?.clientPrincipal?.userRoles.includes('administrator'), [query.data?.clientPrincipal?.userRoles]);

  return { query, isFarmer, isVeterinary, isManager };
};
