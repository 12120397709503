import { onlineManager } from '@tanstack/react-query';
import { useSyncExternalStore } from 'react';

// We need this override to fix an issue that exists in the
// combination of react-query devtools and the OnlineManager
// This should become an issue on GitHub and could become an easy pull request

onlineManager.setOnline = function (online?: boolean): void {
  // @ts-expect-error this.online is actually private, but we want to override the behaviour
  this.online = online;

  this.onOnline();
};

const getSnapshot = () => {
  return onlineManager.isOnline();
};

export const useOnlineStatus = () => {
  const isOnline = useSyncExternalStore(onlineManager.subscribe, getSnapshot);

  return isOnline;
};
