export function getColor(attentionStatus) {
  switch (attentionStatus) {
    case 0:
      return '#7BB662';
    case 1:
      return '#FFE134';
    case 2:
      return '#ffae42';
    case 3:
      return '#C16512';
    default:
      return 'transparent';
  }
}

export function getFontColor(attentionStatus) {
  switch (attentionStatus) {
    case 0:
      return 'white';
    case 1:
      return 'black';
    case 2:
      return 'black';
    case 3:
      return 'white';
    default:
      return 'transparent';
  }
}

export function formatDate(date) {
  if (date) {
    const parts = date.split('T')[0].split('-');
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  }
}
