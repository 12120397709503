import { FC, useEffect, useCallback, useState } from 'react';
import { LocationSetting } from '../../@types';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router';
import { useSettings } from '../../model/settings-queries';
import { useLocationSettings } from '../../model/location-settings-queries';
import { Button, Col, Divider, Form, InputNumber, Radio, Row, Table } from 'antd';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { Spinal } from '@/shared/components';
import FormItem from 'antd/es/form/FormItem';

export const SettingsForm: FC = () => {
  const [form] = useForm<LocationSetting[]>();
  const { location_id } = useParams<{ location_id: string }>();

  const { query: settingsQuery } = useSettings();
  const { query: locationSettingsQuery, update } = useLocationSettings(location_id!);

  const [locationSettings, setLocationSettings] = useState<{ data: (Omit<LocationSetting, 'value'> & { value?: string | number | boolean })[] }>({ data: [] });
  const [intervalValue, setIntervalValue] = useState<number | null>(null);

  const [selectedOption, setSelectedOption] = useState<string | undefined>('true');
  const [koppelValue, setKoppelValue] = useState<number | null>(null);
  const [lactationOneValue, setLactationOneValue] = useState<number | null>(null);
  const [lactationTwoValue, setLactationTwoValue] = useState<number | null>(null);
  const [lactationThreeValue, setLactationThreeValue] = useState<number | null>(null);

  const [selectedDryPeriodOption, setSelectedDryPeriodOption] = useState<string | undefined>('true');
  const [koppelDryPeriodValue, setKoppelDryPeriodValue] = useState<number | null>(null);
  const [dryPeriodLactationOneValue, setDryPeriodLactationOneValue] = useState<number | null>(null);
  const [dryPeriodLactationTwoValue, setDryPeriodLactationTwoValue] = useState<number | null>(null);
  const [dryPeriodLactationThreeValue, setDryPeriodLactationThreeValue] = useState<number | null>(null);

  const [selectedMilkSystemOption, setSelectedMilkSystemOption] = useState<string | undefined>('true');
  const [milkSystemUnknownValue, setMilkSystemUnknownValue] = useState<string | undefined>('true');
  const [milkSystemMilkBarnValue, setMilkSystemMilkBarnValue] = useState<string | undefined>('true');
  const [milkSystemMilkRobotValue, setMilkSystemMilkRobotValue] = useState<string | undefined>('false');
  const [milkSystemBothValue, setMilkSystemBothValue] = useState<string | undefined>('false');

  const [selectedGrazingOption, setSelectedGrazingOption] = useState<string | undefined>('true');
  const [grazingUnknownValue, setGrazingUnknownValue] = useState<string | undefined>('true');
  const [grazingPartlyValue, setGrazingPartlyValue] = useState<string | undefined>('true');
  const [grazingFullValue, setGrazingFullValue] = useState<string | undefined>('false');
  const [grazingNoValue, setGrazingNoValue] = useState<string | undefined>('false');

  const [selectedFeedSystemOption, setSelectedFeedSystemOption] = useState<string | undefined>('true');
  const [feedSystemUnknownValue, setFeedSystemUnknownValue] = useState<string | undefined>('true');
  const [feedSystemPMRValue, setFeedSystemPMRValue] = useState<string | undefined>('true');
  const [feedSystemTMRValue, setFeedSystemTMRValue] = useState<string | undefined>('false');

  const [selectedAttentionBlindInseminationOption, setSelectedAttentionBlindInseminationOption] = useState<string | undefined>('true');
  const [koppelAttentionBlindInseminationValue, setKoppelAttentionBlindInseminationValue] = useState<number | null>(null);
  const [attentionBlindInseminationLactationOneValue, setAttentionBlindInseminationLactationOneValue] = useState<number | null>(null);
  const [attentionBlindInseminationLactationTwoValue, setAttentionBlindInseminationLactationTwoValue] = useState<number | null>(null);
  const [attentionBlindInseminationLactationThreeValue, setAttentionBlindInseminationLactationThreeValue] = useState<number | null>(null);

  useEffect(() => {
    if (locationSettingsQuery.data && settingsQuery.data) {
      const tmpLocationSettings = settingsQuery.data.map(setting => {
        const tmpLocationSetting = locationSettingsQuery.data.find(location => location.settingId === setting.id);

        const tmpValue =
          !tmpLocationSetting || tmpLocationSetting.value === undefined || tmpLocationSetting.value === null
            ? setting.baseValue === undefined || setting.baseValue === null
              ? undefined
              : setting.baseValue
            : tmpLocationSetting.value;

        return {
          id: tmpLocationSetting?.id,
          locationId: location_id!,
          settingId: setting.id,
          value:
            tmpValue !== undefined
              ? ['true', 'false'].includes(tmpValue.toString())
                ? tmpValue?.toString() === 'true' || false
                : Number(tmpValue)
              : undefined,
        };
      });

      setLocationSettings({ data: tmpLocationSettings });

      const setSwitchOption = (settingId, valueSetter) => {
        const setting = tmpLocationSettings.find(setting => setting.settingId === settingId);
        if (setting) {
          valueSetter(setting.value !== undefined ? setting.value.toString() : undefined);
        }
      };

      const handleSetting = (settingId, valueSetter) => {
        const setting = tmpLocationSettings.find(setting => setting.settingId === settingId);
        if (setting) {
          valueSetter(setting.value !== undefined ? Number(setting.value) : null);
        }
      };

      const setMilkSystemValue = (settingId, valueSetter) => {
        const setting = tmpLocationSettings.find(setting => setting.settingId === settingId);
        if (setting) {
          valueSetter(setting.value !== undefined ? setting.value.toString() : undefined);
        }
      };

      const setGrazingValue = (settingId, valueSetter) => {
        const setting = tmpLocationSettings.find(setting => setting.settingId === settingId);
        if (setting) {
          valueSetter(setting.value !== undefined ? setting.value.toString() : undefined);
        }
      };

      const setFeedSystemValue = (settingId, valueSetter) => {
        const setting = tmpLocationSettings.find(setting => setting.settingId === settingId);
        if (setting) {
          valueSetter(setting.value !== undefined ? setting.value.toString() : undefined);
        }
      };

      handleSetting('c69fcf1f-0880-4a84-8f76-3a3cc6a4c5cb', setIntervalValue);

      setSwitchOption('c14ece41-5322-411e-9833-42ec15f17ac0', setSelectedOption);
      handleSetting('117645e2-83b4-11ee-b962-0242ac120002', setKoppelValue);
      handleSetting('ef65a4ec-83b4-11ee-b962-0242ac120002', setLactationOneValue);
      handleSetting('10f6d6a8-83b5-11ee-b962-0242ac120002', setLactationTwoValue);
      handleSetting('2f971e4c-83b5-11ee-b962-0242ac120002', setLactationThreeValue);

      setSwitchOption('69e86b4f-d431-43cd-a272-8b50d7844541', setSelectedDryPeriodOption);
      handleSetting('6f162d28-83b6-11ee-b962-0242ac120002', setKoppelDryPeriodValue);
      handleSetting('65f9a61a-83b7-11ee-b962-0242ac120002', setDryPeriodLactationOneValue);
      handleSetting('6d360fcc-83b7-11ee-b962-0242ac120002', setDryPeriodLactationTwoValue);
      handleSetting('70b17fa6-83b7-11ee-b962-0242ac120002', setDryPeriodLactationThreeValue);

      setSwitchOption('b6d95583-f055-4a32-ab84-7a92d8bb24c4', setSelectedAttentionBlindInseminationOption);
      handleSetting('25802c30-83bc-11ee-b962-0242ac120002', setKoppelAttentionBlindInseminationValue);
      handleSetting('2b267dec-83bc-11ee-b962-0242ac120002', setAttentionBlindInseminationLactationOneValue);
      handleSetting('30554bea-83bc-11ee-b962-0242ac120002', setAttentionBlindInseminationLactationTwoValue);
      handleSetting('354b5004-83bc-11ee-b962-0242ac120002', setAttentionBlindInseminationLactationThreeValue);

      setMilkSystemValue('6178d35a-a636-4d98-9bc3-1344711a8a8a', setMilkSystemUnknownValue);
      setMilkSystemValue('f9e5a904-83b8-11ee-b962-0242ac120002', setMilkSystemMilkBarnValue);
      setMilkSystemValue('fe2d6fec-83b8-11ee-b962-0242ac120002', setMilkSystemMilkRobotValue);
      setMilkSystemValue('00e8c344-83b9-11ee-b962-0242ac120002', setMilkSystemBothValue);

      setGrazingValue('865d8181-6107-404d-9b7d-287242aaa839', setGrazingUnknownValue);
      setGrazingValue('85b0c814-83ba-11ee-b962-0242ac120002', setGrazingPartlyValue);
      setGrazingValue('8c37ce44-83ba-11ee-b962-0242ac120002', setGrazingFullValue);
      setGrazingValue('73d43eb4-83ba-11ee-b962-0242ac120002', setGrazingNoValue);

      setFeedSystemValue('3ddbe498-73a2-4288-942f-e5aa6bf22661', setFeedSystemUnknownValue);
      setFeedSystemValue('a598da30-83bb-11ee-b962-0242ac120002', setFeedSystemPMRValue);
      setFeedSystemValue('b067a392-83bb-11ee-b962-0242ac120002', setFeedSystemTMRValue);

      const milkSystemUnknown = tmpLocationSettings.find(setting => setting.settingId === '6178d35a-a636-4d98-9bc3-1344711a8a8a')?.value;
      const milkSystemMilkBarn = tmpLocationSettings.find(setting => setting.settingId === 'f9e5a904-83b8-11ee-b962-0242ac120002')?.value;
      const milkSystemMilkRobot = tmpLocationSettings.find(setting => setting.settingId === 'fe2d6fec-83b8-11ee-b962-0242ac120002')?.value;
      const milkSystemBoth = tmpLocationSettings.find(setting => setting.settingId === '00e8c344-83b9-11ee-b962-0242ac120002')?.value;

      const grazingUnknown = tmpLocationSettings.find(setting => setting.settingId === '865d8181-6107-404d-9b7d-287242aaa839')?.value;
      const grazingPartly = tmpLocationSettings.find(setting => setting.settingId === '85b0c814-83ba-11ee-b962-0242ac120002')?.value;
      const grazingFull = tmpLocationSettings.find(setting => setting.settingId === '8c37ce44-83ba-11ee-b962-0242ac120002')?.value;
      const grazingNo = tmpLocationSettings.find(setting => setting.settingId === '73d43eb4-83ba-11ee-b962-0242ac120002')?.value;

      const feedSystemUnknown = tmpLocationSettings.find(setting => setting.settingId === '3ddbe498-73a2-4288-942f-e5aa6bf22661')?.value;
      const feedSystemPMR = tmpLocationSettings.find(setting => setting.settingId === 'a598da30-83bb-11ee-b962-0242ac120002')?.value;
      const feedSystemTMR = tmpLocationSettings.find(setting => setting.settingId === 'b067a392-83bb-11ee-b962-0242ac120002')?.value;

      if (milkSystemUnknown) {
        setSelectedMilkSystemOption('unknown');
      } else if (milkSystemMilkBarn) {
        setSelectedMilkSystemOption('milkbarn');
      } else if (milkSystemMilkRobot) {
        setSelectedMilkSystemOption('milkrobot');
      } else if (milkSystemBoth) {
        setSelectedMilkSystemOption('both');
      }

      if (grazingUnknown) {
        setSelectedGrazingOption('unknown');
      } else if (grazingPartly) {
        setSelectedGrazingOption('partly');
      } else if (grazingFull) {
        setSelectedGrazingOption('full');
      } else if (grazingNo) {
        setSelectedGrazingOption('no');
      }

      if (feedSystemUnknown) {
        setSelectedFeedSystemOption('unknown');
      } else if (feedSystemPMR) {
        setSelectedFeedSystemOption('pmr');
      } else if (feedSystemTMR) {
        setSelectedFeedSystemOption('tmr');
      }
    }
  }, [locationSettingsQuery.data, settingsQuery.data, location_id]);

  useEffect(() => {
    form.resetFields();
  }, [form, locationSettings, settingsQuery.data]);

  const save = useCallback(() => {
    const settingsMapping = {
      // Interval bedrijfsbezoeken
      'c69fcf1f-0880-4a84-8f76-3a3cc6a4c5cb': intervalValue,
      // Start inseminatieperiode
      'c14ece41-5322-411e-9833-42ec15f17ac0': selectedOption === 'true' ? true : false,
      '117645e2-83b4-11ee-b962-0242ac120002': koppelValue,
      'ef65a4ec-83b4-11ee-b962-0242ac120002': lactationOneValue,
      '10f6d6a8-83b5-11ee-b962-0242ac120002': lactationTwoValue,
      '2f971e4c-83b5-11ee-b962-0242ac120002': lactationThreeValue,
      // Lengte droogstand
      '69e86b4f-d431-43cd-a272-8b50d7844541': selectedDryPeriodOption === 'true' ? true : false,
      '6f162d28-83b6-11ee-b962-0242ac120002': koppelDryPeriodValue,
      '65f9a61a-83b7-11ee-b962-0242ac120002': dryPeriodLactationOneValue,
      '6d360fcc-83b7-11ee-b962-0242ac120002': dryPeriodLactationTwoValue,
      '70b17fa6-83b7-11ee-b962-0242ac120002': dryPeriodLactationThreeValue,
      // Attentie drempelwaarde programma blind insemineren
      'b6d95583-f055-4a32-ab84-7a92d8bb24c4': selectedAttentionBlindInseminationOption === 'true' ? true : false,
      '25802c30-83bc-11ee-b962-0242ac120002': koppelAttentionBlindInseminationValue,
      '2b267dec-83bc-11ee-b962-0242ac120002': attentionBlindInseminationLactationOneValue,
      '30554bea-83bc-11ee-b962-0242ac120002': attentionBlindInseminationLactationTwoValue,
      '354b5004-83bc-11ee-b962-0242ac120002': attentionBlindInseminationLactationThreeValue,
      // Melksysteem
      '6178d35a-a636-4d98-9bc3-1344711a8a8a': selectedMilkSystemOption === 'unknown' ? true : false,
      'f9e5a904-83b8-11ee-b962-0242ac120002': selectedMilkSystemOption === 'milkbarn' ? true : false,
      'fe2d6fec-83b8-11ee-b962-0242ac120002': selectedMilkSystemOption === 'milkrobot' ? true : false,
      '00e8c344-83b9-11ee-b962-0242ac120002': selectedMilkSystemOption === 'both' ? true : false,
      // Beweiding
      '865d8181-6107-404d-9b7d-287242aaa839': selectedGrazingOption === 'unknown' ? true : false,
      '85b0c814-83ba-11ee-b962-0242ac120002': selectedGrazingOption === 'partly' ? true : false,
      '8c37ce44-83ba-11ee-b962-0242ac120002': selectedGrazingOption === 'full' ? true : false,
      '73d43eb4-83ba-11ee-b962-0242ac120002': selectedGrazingOption === 'no' ? true : false,
      // Voersysteem
      '3ddbe498-73a2-4288-942f-e5aa6bf22661': selectedFeedSystemOption === 'unknown' ? true : false,
      'a598da30-83bb-11ee-b962-0242ac120002': selectedFeedSystemOption === 'pmr' ? true : false,
      'b067a392-83bb-11ee-b962-0242ac120002': selectedFeedSystemOption === 'tmr' ? true : false,
    };

    const updatedData = locationSettings.data.map(setting => {
      const value = settingsMapping[setting.settingId];
      return {
        ...setting,
        value: value !== undefined && value !== null ? value : false,
      };
    });

    console.log(updatedData);
    update.mutate(updatedData);
  }, [update]);
  return (
    <Form form={form} onFinish={save} initialValues={locationSettings}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
          <div style={{ flex: 1 }}>
            <div className={styles.scoreGroupTitles}>
              <div>Kaders</div>
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" className={styles.floatingButton} shape="default" icon={<FontAwesomeIcon icon={faSave} />} size="large">
              <span style={{ marginLeft: '10px' }}>Opslaan</span>
            </Button>
          </div>
        </div>

        <Divider />

        <div className={styles.paramContainer}>
          <div className={styles.parameterTableStyle}>
            <Spinal initializing={!settingsQuery.data} loading={settingsQuery.isLoading}>
              <div className={styles.customStyle}>
                <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                  <Col span={6}>
                    <div style={{ background: '#FFFFFF', padding: '20px', textAlign: 'left' }}>
                      {settingsQuery.data && (
                        <>
                          <h2>{settingsQuery.data.find(setting => setting.name === 'Interval bedrijfsbezoeken')?.name}</h2>
                          <FormItem label="Dagen">
                            <InputNumber
                              style={{ width: '100%' }}
                              value={intervalValue !== null ? intervalValue : undefined}
                              onChange={value => setIntervalValue(value !== null ? Number(value) : null)}
                            />
                          </FormItem>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ background: '#FFFFFF', padding: '20px', textAlign: 'left' }}>
                      {settingsQuery.data && (
                        <>
                          <h2>Start inseminatieperiode</h2>
                          <FormItem>
                            <Radio.Group onChange={e => setSelectedOption(e.target.value)} value={selectedOption}>
                              <Radio value="true">Koppel</Radio>
                              <Radio value="false">Lactatie</Radio>
                            </Radio.Group>
                          </FormItem>
                          {selectedOption === 'true' && (
                            <FormItem label="Dagen">
                              <InputNumber
                                style={{ width: '100%' }}
                                value={koppelValue !== null ? koppelValue : undefined}
                                onChange={value => setKoppelValue(value !== null ? Number(value) : null)}
                              />
                            </FormItem>
                          )}
                          {selectedOption === 'false' && (
                            <>
                              <FormItem label="Lactatie 1" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={lactationOneValue !== null ? lactationOneValue : undefined}
                                  onChange={value => setLactationOneValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                              <FormItem label="Lactatie 2" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={lactationTwoValue !== null ? lactationTwoValue : undefined}
                                  onChange={value => setLactationTwoValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                              <FormItem label="Lactatie 3+" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={lactationThreeValue !== null ? lactationThreeValue : undefined}
                                  onChange={value => setLactationThreeValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ background: '#FFFFFF', padding: '20px', textAlign: 'left' }}>
                      {settingsQuery.data && (
                        <>
                          <h2>Lengte droogstand</h2>
                          <FormItem>
                            <Radio.Group onChange={e => setSelectedDryPeriodOption(e.target.value)} value={selectedDryPeriodOption}>
                              <Radio value="true">Koppel</Radio>
                              <Radio value="false">Lactatie</Radio>
                            </Radio.Group>
                          </FormItem>
                          {selectedDryPeriodOption === 'true' && (
                            <FormItem label="Dagen">
                              <InputNumber
                                style={{ width: '100%' }}
                                value={koppelDryPeriodValue !== null ? koppelDryPeriodValue : undefined}
                                onChange={value => setKoppelDryPeriodValue(value !== null ? Number(value) : null)}
                              />
                            </FormItem>
                          )}
                          {selectedDryPeriodOption === 'false' && (
                            <>
                              <FormItem label="Lactatie 1" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={dryPeriodLactationOneValue !== null ? dryPeriodLactationOneValue : undefined}
                                  onChange={value => setDryPeriodLactationOneValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                              <FormItem label="Lactatie 2" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={dryPeriodLactationTwoValue !== null ? dryPeriodLactationTwoValue : undefined}
                                  onChange={value => setDryPeriodLactationTwoValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                              <FormItem label="Lactatie 3+" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={dryPeriodLactationThreeValue !== null ? dryPeriodLactationThreeValue : undefined}
                                  onChange={value => setDryPeriodLactationThreeValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ background: '#FFFFFF', padding: '20px', textAlign: 'left' }}>
                      {settingsQuery.data && (
                        <>
                          <h2>Attentie drempelwaarde programma blind insemineren</h2>
                          <FormItem>
                            <Radio.Group
                              onChange={e => setSelectedAttentionBlindInseminationOption(e.target.value)}
                              value={selectedAttentionBlindInseminationOption}>
                              <Radio value="true">Koppel</Radio>
                              <Radio value="false">Lactatie</Radio>
                            </Radio.Group>
                          </FormItem>
                          {selectedAttentionBlindInseminationOption === 'true' && (
                            <FormItem label="Dagen">
                              <InputNumber
                                style={{ width: '100%' }}
                                value={koppelAttentionBlindInseminationValue !== null ? koppelAttentionBlindInseminationValue : undefined}
                                onChange={value => setKoppelAttentionBlindInseminationValue(value !== null ? Number(value) : null)}
                              />
                            </FormItem>
                          )}
                          {selectedAttentionBlindInseminationOption === 'false' && (
                            <>
                              <FormItem label="Lactatie 1" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={attentionBlindInseminationLactationOneValue !== null ? attentionBlindInseminationLactationOneValue : undefined}
                                  onChange={value => setAttentionBlindInseminationLactationOneValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                              <FormItem label="Lactatie 2" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={attentionBlindInseminationLactationTwoValue !== null ? attentionBlindInseminationLactationTwoValue : undefined}
                                  onChange={value => setAttentionBlindInseminationLactationTwoValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                              <FormItem label="Lactatie 3+" style={{ textAlign: 'end' }}>
                                <InputNumber
                                  value={attentionBlindInseminationLactationThreeValue !== null ? attentionBlindInseminationLactationThreeValue : undefined}
                                  onChange={value => setAttentionBlindInseminationLactationThreeValue(value !== null ? Number(value) : null)}
                                />
                              </FormItem>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                  <Col span={6}>
                    <div style={{ background: '#FFFFFF', padding: '20px', textAlign: 'left' }}>
                      {settingsQuery.data && (
                        <>
                          <h2>{settingsQuery.data.find(setting => setting.name.startsWith('Melksysteem'))?.name.split(' - ')[0]}</h2>
                          <FormItem>
                            <Radio.Group onChange={e => setSelectedMilkSystemOption(e.target.value)} value={selectedMilkSystemOption}>
                              <Radio value="unknown">
                                {settingsQuery.data.find(setting => setting.id === '6178d35a-a636-4d98-9bc3-1344711a8a8a')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="milkbarn">
                                {settingsQuery.data.find(setting => setting.id === 'f9e5a904-83b8-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="milkrobot">
                                {settingsQuery.data.find(setting => setting.id === 'fe2d6fec-83b8-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="both">
                                {settingsQuery.data.find(setting => setting.id === '00e8c344-83b9-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                            </Radio.Group>
                          </FormItem>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ background: '#FFFFFF', padding: '20px', textAlign: 'left' }}>
                      {settingsQuery.data && (
                        <>
                          <h2>{settingsQuery.data.find(setting => setting.name.startsWith('Beweiding'))?.name.split(' - ')[0]}</h2>
                          <FormItem>
                            <Radio.Group onChange={e => setSelectedGrazingOption(e.target.value)} value={selectedGrazingOption}>
                              <Radio value="unknown">
                                {settingsQuery.data.find(setting => setting.id === '865d8181-6107-404d-9b7d-287242aaa839')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="partly">
                                {settingsQuery.data.find(setting => setting.id === '85b0c814-83ba-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="full">
                                {settingsQuery.data.find(setting => setting.id === '8c37ce44-83ba-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="no">
                                {settingsQuery.data.find(setting => setting.id === '73d43eb4-83ba-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                            </Radio.Group>
                          </FormItem>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ background: '#FFFFFF', padding: '20px', textAlign: 'left' }}>
                      {settingsQuery.data && (
                        <>
                          <h2>{settingsQuery.data.find(setting => setting.name.startsWith('Voersysteem'))?.name.split(' - ')[0]}</h2>
                          <FormItem>
                            <Radio.Group onChange={e => setSelectedFeedSystemOption(e.target.value)} value={selectedFeedSystemOption}>
                              <Radio value="unknown">
                                {settingsQuery.data.find(setting => setting.id === '3ddbe498-73a2-4288-942f-e5aa6bf22661')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="pmr">
                                {settingsQuery.data.find(setting => setting.id === 'a598da30-83bb-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                              <Radio value="tmr">
                                {settingsQuery.data.find(setting => setting.id === 'b067a392-83bb-11ee-b962-0242ac120002')?.name.split(' - ')[1]}
                              </Radio>
                            </Radio.Group>
                          </FormItem>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Spinal>
          </div>
        </div>
      </div>
    </Form>
  );
};
