import { Spinal } from '@/shared/components';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Empty, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCategories } from '../../model/categories-queries';
import ParameterTable from '../parameter-table';
import ScoreTypeSwitches from '../score-type-switches';
import styles from './styles.module.scss';

import { LocationScoreGroupSetting } from '../../@types';
import { useScoreGroupSettings } from '../../model/score-group-settings.queries';

export const GenerateScoreGroupForm: FC = () => {
  const [form] = useForm<LocationScoreGroupSetting>();

  const { location_id, score_group_id, category_id } = useParams<{ location_id: string; score_group_id: string; category_id: string }>();

  const { query: categoriesQuery } = useCategories();
  const { query: scoreGroupSettingsQuery, update } = useScoreGroupSettings(score_group_id || '', location_id);

  const parametersCached = useMemo(() => scoreGroupSettingsQuery.data?.scoreGroupParameters, [scoreGroupSettingsQuery.data]);
  const scoreTypeCached = useMemo(() => scoreGroupSettingsQuery.data?.scoreGroupScoreType, [scoreGroupSettingsQuery.data]);
  const scoreGroup = useMemo(() => {
    const category = categoriesQuery.data?.find(f => f.id === category_id);
    const scoreGroup = category?.scoreGroups.find(p => p.id === score_group_id);
    return scoreGroup;
  }, [category_id, categoriesQuery.data, score_group_id]);

  useEffect(() => {
    if (scoreGroupSettingsQuery.data) {
      form.resetFields();

      const locationScoreGroupScoreTypes =
        scoreGroupSettingsQuery.data.scoreGroupScoreType?.map(item => {
          const locationScoreGroupScoreTypeEnabled = location_id ? item.scoreType.locationSpecificScoreTypes[0]?.value : undefined;

          return {
            name: item.scoreType.description,
            scoreTypeId: item.scoreType.id,
            value: locationScoreGroupScoreTypeEnabled === undefined ? item.baseValue : locationScoreGroupScoreTypeEnabled,
          };
        }) || [];

      form.setFieldsValue({
        locationScoreGroupScoreTypes,
      });
    }
  }, [scoreGroupSettingsQuery.data, form, location_id]);

  const save = useCallback(
    values => {
      update.mutate(values);
    },
    [update]
  );

  if (!scoreGroupSettingsQuery.data) {
    return <Empty className={styles.emptyStyle} description={<span>Geen settings gevonden</span>} />;
  }

  return (
    <Form form={form} onFinish={save}>
      <Spinal initializing={false} loading={scoreGroupSettingsQuery.isLoading}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
          <div style={{ flex: 1 }}>
            <div className={styles.scoreGroupTitles}>
              <div>{scoreGroup?.code}</div>
            </div>
            <div className={styles.scoreGroupTitles}>
              <div>{scoreGroup?.name}</div>
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>
            {location_id && (
              <Button type="primary" htmlType="submit" className={styles.floatingButton} shape="default" icon={<FontAwesomeIcon icon={faSave} />} size="large">
                <span style={{ marginLeft: '10px' }}>Opslaan</span>
              </Button>
            )}
          </div>
        </div>

        <Divider />
        <div>
          <div className={styles.scoreTypeTitle}>Parameters</div>
        </div>
        <div className={styles.paramContainer}>
          <div className={styles.parameterTableStyle}>
            <Spinal initializing={!scoreGroup} loading={categoriesQuery.isLoading}>
              {scoreGroup && scoreGroupSettingsQuery.data.scoreGroupParameters?.length > 0 && parametersCached ? (
                <ParameterTable scoreGroupSetting={scoreGroupSettingsQuery.data} />
              ) : (
                <Empty className={styles.emptyStyle} description={<span>Deze scoregroep heeft geen instelbare parameters.</span>} />
              )}
            </Spinal>
          </div>
        </div>
        <Divider />
        <div>
          <div className={styles.parameterTitle}>Scoretypes</div>
        </div>
        <div className={styles.scoreTypesContainter}>
          <div>
            <Spinal initializing={!scoreGroupSettingsQuery} loading={categoriesQuery.isLoading}>
              {scoreGroup && scoreGroupSettingsQuery.data.scoreGroupScoreType?.length > 0 && scoreTypeCached ? (
                <ScoreTypeSwitches />
              ) : (
                <Empty className={styles.emptyStyle} description={<span>Geen ScoreTypes gevonden.</span>} />
              )}
            </Spinal>
          </div>
        </div>
      </Spinal>
    </Form>
  );
};

export default GenerateScoreGroupForm;
