import { FC, useCallback, useEffect } from 'react';
import { LocationScoreTypeSetting, ScoreType } from '../../@types';
import { Form, Button, Empty, Row, Space, Switch } from 'antd';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'antd/es/form/Form';
import { useScoreTypeSettings } from '../../model/score-type-setting-queries';
import { useParams } from 'react-router';
import DynamicTableRows from './DynamicTableRows';

interface ScoreTypeFormProps {
  selectedScoreType: ScoreType | undefined;
}

const ScoreTypeForm: FC<ScoreTypeFormProps> = ({ selectedScoreType }) => {
  const [form] = useForm<LocationScoreTypeSetting>();
  const { location_id } = useParams<{ location_id: string }>();
  const { query: scoreTypeSettingQuery, update } = useScoreTypeSettings(selectedScoreType?.id || '', location_id);

  useEffect(() => {
    let updatedValues;
    if (scoreTypeSettingQuery.data && Array.isArray(scoreTypeSettingQuery.data.locationScoreTypes)) {
      if (scoreTypeSettingQuery.data.locationScoreTypes.length === 0) {
        updatedValues = selectedScoreType?.allowedValues.map(item => ({
          locationId: location_id,
          scoreTypeId: selectedScoreType?.id || '',
          score: item.value,
          directActionValue: item.isDirectAction, // Use isDirectAction as the default value
          attentionValue: item.isAttention, // Use isAttention as the default value
          directActionGreaterThanValue: '',
          directActionLessThanValue: '',
          attentionGreaterThanValue: '',
          attentionLessThanValue: '',
        }));
      } else {
        updatedValues = scoreTypeSettingQuery.data.locationScoreTypes.map(locationItem => {
          const defaultDirectActionValue = selectedScoreType?.allowedValues.find(val => val.value === locationItem.score)?.isDirectAction;
          const defaultAttentionValue = selectedScoreType?.allowedValues.find(val => val.value === locationItem.score)?.isAttention;
          return {
            locationId: location_id,
            scoreTypeId: selectedScoreType?.id || '',
            score: locationItem.score,
            directActionValue: locationItem.directActionValue !== undefined ? locationItem.directActionValue : defaultDirectActionValue,
            attentionValue: locationItem.attentionValue !== undefined ? locationItem.attentionValue : defaultAttentionValue,
            directActionGreaterThanValue: locationItem.directActionGreaterThanValue,
            directActionLessThanValue: locationItem.directActionLessThanValue,
            attentionGreaterThanValue: locationItem.attentionGreaterThanValue,
            attentionLessThanValue: locationItem.attentionLessThanValue,
          };
        });
      }
    }
    form.setFieldsValue({
      locationScoreTypes: updatedValues,
    });
  }, [form, scoreTypeSettingQuery.data, selectedScoreType?.allowedValues, selectedScoreType?.id, location_id]);

  const save = useCallback(
    values => {
      update.mutate(values);
    },
    [update]
  );

  return (
    <div style={{ padding: '20px', width: '100%', overflowY: 'auto' }}>
      <Form form={form} onFinish={save}>
        {selectedScoreType ? (
          <>
            <div style={{ textAlign: 'left', padding: '10px' }}>
              <Space>
                {location_id && (
                  <Button type="primary" htmlType="submit" shape="default" icon={<FontAwesomeIcon icon={faSave} />} size="large">
                    <span style={{ marginLeft: '10px' }}>Opslaan</span>
                  </Button>
                )}
                <span className={styles.customText}>{selectedScoreType.description}</span>
              </Space>
            </div>
            <Row>
              <span style={{ padding: '10px' }}>
                <table className={styles.tableActie}>
                  {Number.isNaN(+selectedScoreType.allowedValues[0].value) ? (
                    <>
                      <thead>
                        <tr>
                          <th colSpan={3}>In Bezoekrapport vermelden in:</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th>Directe actie</th>
                          <th>Attentie</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedScoreType.allowedValues.map((item, index) => (
                          <tr key={item.id}>
                            <Form.Item name={['locationScoreTypes', index, 'locationId']} initialValue={location_id} hidden />
                            <Form.Item name={['locationScoreTypes', index, 'scoreTypeId']} initialValue={selectedScoreType.id} hidden />
                            <Form.Item name={['locationScoreTypes', index, 'score']} initialValue={item.value} hidden />
                            <td>{item.value?.toString()}</td>
                            <td>
                              {selectedScoreType.directActionManageable ? (
                                <Form.Item
                                  name={['locationScoreTypes', index, 'directActionValue']}
                                  valuePropName="checked"
                                  style={{ marginBottom: '0px', padding: '8px' }}>
                                  <Switch disabled={!location_id} defaultChecked={item.isDirectAction} />
                                </Form.Item>
                              ) : (
                                <p>Geen instelopties</p>
                              )}
                            </td>
                            <td>
                              {selectedScoreType.attentionManageable ? (
                                <Form.Item
                                  name={['locationScoreTypes', index, 'attentionValue']}
                                  valuePropName="checked"
                                  style={{ marginBottom: '0px', padding: '8px' }}>
                                  <Switch disabled={!location_id} defaultChecked={item.isAttention} />
                                </Form.Item>
                              ) : (
                                <p>Geen instelopties</p>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th colSpan={3}>In Bezoekrapport vermelden in:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <DynamicTableRows
                          selectedScoreType={selectedScoreType}
                          location_id={location_id}
                          locationScoreTypes={scoreTypeSettingQuery.data?.locationScoreTypes}
                        />
                      </tbody>
                    </>
                  )}
                </table>
              </span>
            </Row>
          </>
        ) : (
          <div style={{ margin: 'auto' }}>
            <Empty description={<span>Geen Scoretype parameters gevonden.</span>} />
          </div>
        )}
      </Form>
    </div>
  );
};

export default ScoreTypeForm;
