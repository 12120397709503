import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOnlineStatus } from '@/shared/hooks/useOnlineStatus';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft, faArrowRight, faBallotCheck, faRightFromBracket, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { Badge, Button, Layout, Menu, Skeleton, Tooltip, theme, Typography } from 'antd';
import { Suspense } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { generateUrl } from '@/shared/utilities/generate-url';
import { useLocations } from '@/modules/location-selector/model/location-queries';
import { useUserInfo } from '@/modules/user/model/queries';
import { getMenu } from './menu';
import { UpdateAvailabillity } from '@/modules/service-worker/components/update-availability';

const logoutURL = generateUrl('/.auth/logout', false); // + `?post_login_redirect_uri=${window.location.href}`;

const { Content, Header } = Layout;

export const BaseLayout = (): JSX.Element => {
  const {
    token: { colorPrimary, colorWhite },
  } = theme.useToken();

  const isOnline = useOnlineStatus();
  const pausedMutations = useIsMutating({ predicate: mutation => mutation.state.isPaused });
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(collapsed => !collapsed);
  }, []);

  const { location_id } = useParams<{ location_id: string }>();
  const { query: locationQuery } = useLocations();
  const locationObj = useMemo(() => locationQuery.data?.find(x => x.id == location_id), [locationQuery.data, location_id]);
  const logoLocation = useMemo(() => locationObj?.veterinaryPractice?.logoLocation, [locationObj]);
  const ubnNumber = locationObj?.locationId;
  const companyName = locationObj?.locationName;
  const { Text } = Typography;
  const { isManager, isVeterinary, isFarmer } = useUserInfo();

  const handleMenuItemClick = useCallback(
    ({ key }) => (key === 'veeonline' ? window.open(`https://www.veeonline.nl/home/veehouderhome?StartVeeOnlineMetUbn=${ubnNumber}`) : navigate(key)),
    [navigate, ubnNumber]
  );

  const currentMenu = useMemo(() => getMenu(isManager, isVeterinary, isFarmer, location_id), [location_id, isManager, isVeterinary, isFarmer]);

  const goToLocations = useCallback(() => navigate('/bedrijven/overzicht'), [navigate]);

  const [openMenuKeys, setOpenMenuKeys] = useState<string[]>([]);
  const selectedKeys = useMemo(() => {
    const [, ...keys] = location.pathname.split('/');
    let tmpSelectedKeys = '';
    let stop = location_id ? 3 : 2;
    if (keys.length > stop && keys[2] === 'report') {
      // report route is an exemption
      stop++;
    } else if (keys.length < stop) {
      stop = keys.length;
    }
    for (let i = 0; i < stop; i++) {
      tmpSelectedKeys += `/${keys[i]}`;
    }
    return [tmpSelectedKeys];
  }, [location]);

  const doLogout = useCallback(async () => {
    queryClient.setDefaultOptions({ queries: { enabled: false } });
    queryClient.removeQueries();

    // Remove cached queries, retain cached mutations
    const cache = window.localStorage.getItem('REACT_QUERY_OFFLINE_CACHE');
    if (cache != null) {
      const data = JSON.parse(cache);
      data.clientState.queries = [];
      const cachedMutations = JSON.stringify(data);
      window.localStorage.setItem('REACT_QUERY_OFFLINE_CACHE', cachedMutations);
    }

    window.open(logoutURL, '_self');
  }, [queryClient]);

  const onOpenChange = useCallback((openKeys: string[]) => {
    setOpenMenuKeys(openKeys);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', height: '100%' }}>
      <Header
        style={{
          background: colorWhite,
          position: 'relative',
          height: '80px',
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '200px auto  auto 40px 40px',
          padding: '0 26px',
        }}>
        <Link style={{ display: 'grid', alignItems: 'center', position: 'relative', justifySelf: 'center' }} to="/">
          <img src="/logo.png" style={{ maxWidth: '200px', maxHeight: '80px', height: 'auto', width: '200px' }} />
        </Link>

        <div style={{ display: 'grid', alignItems: 'center', position: 'relative' }}>
          {logoLocation && (
            <img
              src={`/logos/${logoLocation}`}
              style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', maxWidth: '200px', maxHeight: '80px', height: 'auto', width: 'auto' }}
            />
          )}
        </div>
        <div
          style={{
            display: 'grid',
            justifyContent: 'end',
            gridAutoRows: 'min-content',
            alignContent: 'center',
            paddingRight: '40px',
          }}>
          {companyName && <Text strong>{companyName}</Text>}
          {ubnNumber && <Text strong>{ubnNumber}</Text>}
        </div>

        <div style={{ height: '80px', justifySelf: 'center', display: 'flex', alignItems: 'center' }}>
          <Tooltip title={isOnline ? 'Online' : 'Offline'}>
            <Badge count={pausedMutations} offset={[5, -5]} size="small">
              {isOnline ? (
                <FontAwesomeIcon style={{ transform: 'scale(1.5)' }} color={colorPrimary} icon={faWifi} />
              ) : (
                <FontAwesomeIcon style={{ transform: 'scale(1.5)' }} color="#bbb" icon={faWifiSlash} />
              )}
            </Badge>
          </Tooltip>
        </div>

        <div style={{ justifySelf: 'center', display: 'grid', alignItems: 'center' }}>
          <Tooltip title="Uitloggen">
            <Button onClick={doLogout} type="text">
              <FontAwesomeIcon style={{ transform: 'scale(1.5)' }} color={colorPrimary} icon={faRightFromBracket} />
            </Button>
          </Tooltip>
        </div>
      </Header>
      <div style={{ height: 'calc(100vh - 80px)', display: 'grid', gridTemplateColumns: 'min-content 1fr' }}>
        <div style={{ position: 'relative', height: '100%', minHeight: 0, backgroundColor: '#fff' }}>
          <div style={{ position: 'relative', height: 'calc(100% - 88px)', overflow: 'auto' }}>
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={selectedKeys}
              openKeys={openMenuKeys}
              // rootClassName={styles.menu}
              onClick={handleMenuItemClick}
              inlineCollapsed={collapsed}
              items={currentMenu}
              onOpenChange={onOpenChange}></Menu>
          </div>
          {(isManager || isVeterinary) && (
            <div style={{ position: 'absolute', bottom: '48px', left: '16px', display: 'grid', alignItems: 'center' }}>
              <Button type="primary" onClick={goToLocations} style={{ borderRadius: '8px' }}>
                <FontAwesomeIcon style={{ margin: '0 auto' }} icon={faBallotCheck} />
              </Button>
            </div>
          )}
          <div style={{ position: 'absolute', bottom: '8px', left: '16px', display: 'grid', alignItems: 'center' }}>
            <Button type="primary" onClick={toggleCollapsed} style={{ borderRadius: '8px' }}>
              <FontAwesomeIcon style={{ margin: '0 auto' }} icon={collapsed ? faArrowRight : faArrowLeft} />
            </Button>
          </div>
        </div>
        <Content style={{ height: 'calc(100vh - 80px)', overflowY: 'auto', position: 'relative' }}>
          <UpdateAvailabillity />
          <Suspense fallback={<Skeleton active loading />}>
            <Outlet />
          </Suspense>
        </Content>
      </div>
    </Layout>
  );
};
