import type ky from 'ky';
import type { Options } from 'ky';
import { generateUrl } from './generate-url';

interface JsonApiClient {
  get: <T>(input?: string, options?: Options) => Promise<T>;
  post: <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) => Promise<T>;
  patch: <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) => Promise<T>;
  put: <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) => Promise<T>;
  delete: <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) => Promise<T>;
}

const createJsonApiClient =
  (kyInstance: typeof ky) =>
  (path: string, appendApi?: boolean): JsonApiClient => {
    const extendedKyInstance = kyInstance.extend({
      prefixUrl: generateUrl(path, appendApi),
    });

    const instance = <T>(input?: string, options?: Options) => extendedKyInstance(input || '', { ...options }).json<T>();

    instance.get = <T>(input?: string, options?: Options) => extendedKyInstance.get(input || '', { ...options }).json<T>();

    instance.post = <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) =>
      extendedKyInstance.post(input || '', { json: json, ...options }).json<T>();

    instance.patch = <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) =>
      extendedKyInstance.patch(input || '', { json: json, ...options }).json<T>();

    instance.put = <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) =>
      extendedKyInstance.put(input || '', { json: json, ...options }).json<T>();

    instance.delete = <T>(input?: string, json?: unknown, options?: Omit<Options, 'json'>) =>
      extendedKyInstance.delete(input || '', { json: json, ...options }).json<T>();

    return instance;
  };

export { createJsonApiClient };
