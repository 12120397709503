import { Menu } from 'antd';
import { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useScoreTypes } from '../../model/score-type-queries';
import styles from './styles.module.scss';

interface ScoreTypeSelectorProps {
  isMenuOpen: boolean;
}

const ScoreTypeSelector: FC<ScoreTypeSelectorProps> = ({ isMenuOpen }) => {
  const { query: scoreTypesQuery } = useScoreTypes();
  const { score_type_id, location_id } = useParams<{ score_type_id: string; location_id: string }>();

  const scoreTypes = useMemo(() => {
    const filteredScoreTypes = scoreTypesQuery.data?.filter(scoretype => scoretype.type !== 'Input');
    return filteredScoreTypes;
  }, [scoreTypesQuery.data]);

  return isMenuOpen ? (
    <div style={{ position: 'relative', height: '100%', backgroundColor: '#f5f5f5', width: '22em' }}>
      <div className={styles.background}>
        <Menu mode="vertical">
          {scoreTypes?.map(scoreType => (
            <Menu.Item className={scoreType.id === score_type_id ? styles.selected : undefined} key={scoreType.id}>
              <Link to={location_id ? `/instellingen/${location_id}/scoretype-settings/${scoreType?.id}` : `/instellingen/scoretype-settings/${scoreType?.id}`}>
                {scoreType.description}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  ) : null;
};

export default ScoreTypeSelector;
