import { faGreaterThanEqual, faLessThanEqual } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Select } from 'antd';

const DynamicTableRows = ({ selectedScoreType, location_id, locationScoreTypes }) => {
  const scoreTypeConfig = {
    bcs: {
      directActionLessThanValue: locationScoreTypes?.[0]?.directActionLessThanValue || '5,00',
      directActionGreaterThanValue: locationScoreTypes?.[0]?.directActionGreaterThanValue || '1,00',
      attentionLessThanValue: locationScoreTypes?.[0]?.attentionLessThanValue || '4,0',
      attentionGreaterThanValue: locationScoreTypes?.[0]?.attentionGreaterThanValue || '2,25',
    },
    calcium: {
      directActionLessThanValue: locationScoreTypes?.[0]?.directActionLessThanValue || '2,0',
      directActionGreaterThanValue: locationScoreTypes?.[0]?.directActionGreaterThanValue || '1,0',
      attentionLessThanValue: locationScoreTypes?.[0]?.attentionLessThanValue || '2,2',
      attentionGreaterThanValue: locationScoreTypes?.[0]?.attentionGreaterThanValue || '2,0',
    },
    ketoBloed: {
      directActionLessThanValue: locationScoreTypes?.[0]?.directActionLessThanValue || '10,0',
      directActionGreaterThanValue: locationScoreTypes?.[0]?.directActionGreaterThanValue || '2,1',
      attentionLessThanValue: locationScoreTypes?.[0]?.attentionLessThanValue || '2,0',
      attentionGreaterThanValue: locationScoreTypes?.[0]?.attentionGreaterThanValue || '1,2',
    },
    ketoMelk: {
      directActionLessThanValue: locationScoreTypes?.[0]?.directActionLessThanValue || '1000',
      directActionGreaterThanValue: locationScoreTypes?.[0]?.directActionGreaterThanValue || '200',
      attentionLessThanValue: locationScoreTypes?.[0]?.attentionLessThanValue || '200',
      attentionGreaterThanValue: locationScoreTypes?.[0]?.attentionGreaterThanValue || '100',
    },
    phUrine: {
      directActionLessThanValue: locationScoreTypes?.[0]?.directActionLessThanValue || '5,5',
      directActionGreaterThanValue: locationScoreTypes?.[0]?.directActionGreaterThanValue || '4,0',
      attentionLessThanValue: locationScoreTypes?.[0]?.attentionLessThanValue || '6,0',
      attentionGreaterThanValue: locationScoreTypes?.[0]?.attentionGreaterThanValue || '5,6',
    },
    default: {
      directActionLessThanValue: locationScoreTypes?.[0]?.directActionLessThanValue,
      directActionGreaterThanValue: locationScoreTypes?.[0]?.directActionGreaterThanValue,
      attentionLessThanValue: locationScoreTypes?.[0]?.attentionLessThanValue,
      attentionGreaterThanValue: locationScoreTypes?.[0]?.attentionGreaterThanValue,
    },
  };

  const config = scoreTypeConfig[selectedScoreType.name] || scoreTypeConfig.default;
  const getValueOrPlaceholder = (value, placeholder) => (value === '0' ? placeholder : value);

  return (
    <>
      <tr>
        <th></th>
        <th style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon={faLessThanEqual} />
        </th>
        <th style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon={faGreaterThanEqual} />
        </th>
      </tr>
      <tr>
        <td>Directe actie</td>
        {selectedScoreType.directActionManageable ? (
          <>
            <td style={{ textAlign: 'center', paddingTop: '31px' }}>
              <Form.Item name={['locationId']} initialValue={location_id} hidden />
              <Form.Item name={['scoreTypeId']} initialValue={selectedScoreType.id} hidden />
              <Form.Item name={['directActionLessThanValue']} initialValue={config.directActionLessThanValue}>
                <Select style={{ width: 100 }} allowClear disabled={!location_id}>
                  {selectedScoreType.allowedValues.map(item => (
                    <Select.Option key={item.id} value={item.value}>
                      {getValueOrPlaceholder(item.value, config.directActionLessThanValue)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </td>
            <td style={{ textAlign: 'center', paddingTop: '31px' }}>
              <Form.Item name={['directActionGreaterThanValue']} initialValue={config.directActionGreaterThanValue}>
                <Select style={{ width: 100 }} allowClear disabled={!location_id}>
                  {selectedScoreType.allowedValues.map(item => (
                    <Select.Option key={item.id} value={item.value}>
                      {getValueOrPlaceholder(item.value, config.directActionGreaterThanValue)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </td>
          </>
        ) : (
          <p>Geen instelopties</p>
        )}
      </tr>
      <tr>
        <td>Attentie</td>
        {selectedScoreType.attentionManageable ? (
          <>
            <td style={{ textAlign: 'center', paddingTop: '31px' }}>
              <Form.Item name={['attentionLessThanValue']} initialValue={config.attentionLessThanValue}>
                <Select style={{ width: 100 }} allowClear disabled={!location_id}>
                  {selectedScoreType.allowedValues.map(item => (
                    <Select.Option key={item.id} value={item.value}>
                      {getValueOrPlaceholder(item.value, config.attentionLessThanValue)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </td>
            <td style={{ textAlign: 'center', paddingTop: '31px' }}>
              <Form.Item name={['attentionGreaterThanValue']} initialValue={config.attentionGreaterThanValue}>
                <Select style={{ width: 100 }} allowClear disabled={!location_id}>
                  {selectedScoreType.allowedValues.map(item => (
                    <Select.Option key={item.id} value={item.value}>
                      {getValueOrPlaceholder(item.value, config.attentionGreaterThanValue)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </td>
          </>
        ) : (
          <p>Geen instelopties</p>
        )}
      </tr>
    </>
  );
};

export default DynamicTableRows;
