import { faArrowUpRightFromSquare, faCow, faFarm, faFileExport, faFolder, faGaugeHigh, faGear, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemType } from 'antd/es/menu/hooks/useItems';

export const getMenu = (isManager?: boolean, isVeterinary?: boolean, isFarmer?: boolean, locationId?: string): ItemType[] => {
  if (!locationId) {
    // Geen bedrijf geselecteerd
    return [
      {
        key: '/bedrijven',
        label: 'Bedrijven',
        icon: <FontAwesomeIcon icon={faFarm} />,
        children: [
          {
            key: '/bedrijven/overzicht',
            label: 'Overzicht',
          },
          ...(isManager
            ? [
                {
                  key: '/bedrijven/beheer',
                  label: 'Beheer',
                },
              ]
            : []),
        ],
      },
      ...(isVeterinary
        ? [
            {
              key: '/bedrijven-inschrijven',
              label: 'Bedrijven inschrijven',
              icon: <FontAwesomeIcon icon={faPlus} />,
            },
          ]
        : []),
      {
        key: `/instellingen`,
        label: 'Instellingen',
        icon: <FontAwesomeIcon icon={faGear} />,
        children: [
          ...(isManager
            ? [
                {
                  key: `/instellingen/scoretype-settings`,
                  label: 'Scoretypes',
                },
              ]
            : []),
          {
            key: `/instellingen/scoregroup-settings`,
            label: 'Scoregroepen',
          },
        ],
      },
      {
        key: '/documenten',
        label: 'Documenten Vetwerk',
        icon: <FontAwesomeIcon icon={faFolder} />,
      },
      ...(isManager
        ? [
            {
              key: '/exports',
              label: 'Exports',
              icon: <FontAwesomeIcon icon={faFileExport} />,
            },
          ]
        : []),
    ];
  } else {
    // Bedrijf geselecteerd
    return [
      {
        key: `/bedrijfsbezoek`,
        label: 'Bedrijfsbezoek',
        icon: <FontAwesomeIcon icon={faFarm} />,
        children: [
          {
            key: `/bedrijfsbezoek/${locationId}/data-uitwisselingen`,
            label: 'Data-uitwisselingen',
          },
          {
            key: `/bedrijfsbezoek/${locationId}/report/Vastzetlijst`,
            label: 'Vastzetlijst',
          },
          {
            key: `/bedrijfsbezoek/${locationId}/report/Invullijst`,
            label: 'Invullijst',
          },
          {
            key: `/bedrijfsbezoek/${locationId}/report/Bezoekrapport`,
            label: 'Bezoekrapport',
          },
          {
            key: `/bedrijfsbezoek/${locationId}/report/Adviesrapport`,
            label: 'Adviesrapport',
          },
          !isFarmer // hide "Scores invoer" for farmers
            ? {
                key: `/bedrijfsbezoek/${locationId}/score`,
                label: 'Scores invoer',
              }
            : null,
          {
            key: `/bedrijfsbezoek/${locationId}/report/ScoresOverzicht`,
            label: 'Scores overzicht',
          },
        ],
      },
      {
        key: `/cockpit-bedrijf`,
        label: 'Cockpit bedrijf',
        icon: <FontAwesomeIcon icon={faGaugeHigh} />,
        children: [
          {
            key: `/cockpit-bedrijf/${locationId}/report/DashboardBedrijf`,
            label: 'Dashboard bedrijf',
          },
          {
            key: `/cockpit-bedrijf/${locationId}/report/Melkproductie`,
            label: 'Melkproductie',
          },
          {
            key: `/cockpit-bedrijf/${locationId}/report/GezondheidKoeien`,
            label: 'Gezondheid koeien',
          },
          {
            key: `/cockpit-bedrijf/${locationId}/report/Vruchtbaarheid`,
            label: 'Vruchtbaarheid',
          },
          {
            key: `/cockpit-bedrijf/${locationId}/report/Uiergezondheid`,
            label: 'Uiergezondheid',
          },
          {
            key: `/cockpit-bedrijf/${locationId}/report/OpstartLactatie`,
            label: 'Opstart lactatie',
          },
          {
            key: `/cockpit-bedrijf/${locationId}/report/Droogstand`,
            label: 'Droogstand',
          },
          {
            key: `/cockpit-bedrijf/${locationId}/report/Jongvee`,
            label: 'Jongvee',
          },
        ],
      },
      {
        key: `/dierkaart/${locationId}/report/Dierkaart`,
        label: 'Dierkaart',
        icon: <FontAwesomeIcon icon={faCow} />,
      },
      {
        key: `/exports/${locationId}`,
        label: 'Exports',
        icon: <FontAwesomeIcon icon={faFileExport} />,
      },
      ...(isManager || isVeterinary
        ? [
            {
              key: `/instellingen`,
              label: 'Instellingen',
              icon: <FontAwesomeIcon icon={faGear} />,
              children: [
                {
                  key: `/instellingen/${locationId}/kaders`,
                  label: 'Kaders',
                },
                {
                  key: `/instellingen/${locationId}/scoretype-settings`,
                  label: 'Scoretypes',
                },
                {
                  key: `/instellingen/${locationId}/scoregroup-settings`,
                  label: 'Scoregroepen',
                },
              ],
            },
          ]
        : []),
      {
        key: 'veeonline',
        label: 'VeeOnline',
        icon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
      },
    ];
  }
};
