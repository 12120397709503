import { FC, Suspense, useEffect, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ConfigProvider } from 'antd';
import nlNL from 'antd/locale/nl_NL';
import { useAtomValue } from 'jotai';
import { ErrorBoundary } from 'shared/components';
import { atoms } from '@/shared/atoms';
import { InitWrapper } from './InitWrapper';
import { AppRouter } from './router';
import { darkTheme, lightheme } from '@/shared/model/theme';
import { AliasToken } from 'antd/es/theme/internal';
import { createBrowserHistory } from 'history';
import { retryFailedMutations } from 'modules/score-app/model/animal-score-queries';
import 'dayjs/locale/nl';

const APP_INSIGHTS_CONNECTION_STRING_DEV =
  'InstrumentationKey=919b6c08-13ce-48cd-906b-3e899b90c774;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=7723f9aa-47e9-4a35-90b2-9cab8e4e73eb';
const APP_INSIGHTS_CONNECTION_STRING_TST =
  'InstrumentationKey=99139258-eb03-4361-b56a-2d6af9aa3df1;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=55017f68-b2a3-4a09-a2c4-af2879c9ac6b';
const APP_INSIGHTS_CONNECTION_STRING_ACC =
  'InstrumentationKey=8f803f98-c9e8-4096-a7c5-1e98f18b3bbf;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=c5719a0d-4ada-4e0b-8bfd-f21fd5e0c21d';
const APP_INSIGHTS_CONNECTION_STRING_PRD =
  'InstrumentationKey=c8468499-43b4-4d97-992c-77cdab154c81;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=9bc46162-81b7-420e-864e-c56138a764f2';

const getConnectionString = () => {
  const host = window.location.hostname;
  if (host.includes('-tst')) return APP_INSIGHTS_CONNECTION_STRING_TST;
  else if (host.includes('-acc')) return APP_INSIGHTS_CONNECTION_STRING_ACC;
  else if (host.includes('app.vetwerk.nl')) return APP_INSIGHTS_CONNECTION_STRING_PRD;
  return APP_INSIGHTS_CONNECTION_STRING_DEV;
};

const browserHistory = createBrowserHistory();
const appiPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: getConnectionString(),
    enableAutoRouteTracking: true,
    extensions: [appiPlugin],
    extensionConfig: {
      [appiPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export const App: FC = () => {
  const theme = useAtomValue(atoms.theme);
  const [themeConfig, setThemeConfig] = useState<Partial<AliasToken>>();

  useEffect(() => {
    setThemeConfig(theme.isDarktheme ? darkTheme : lightheme);
  }, [theme.isDarktheme, theme]);

  useEffect(() => {
    retryFailedMutations();
  }, []);

  return (
    <Suspense fallback={<p>loading</p>}>
      {/* <AuthWrapper> */}
      <InitWrapper>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.primaryColor,
              ...themeConfig,
            },
          }}
          locale={nlNL}>
          <ErrorBoundary appInsights={appInsights}>
            <AppRouter />
          </ErrorBoundary>
        </ConfigProvider>
      </InitWrapper>
      {/* </AuthWrapper> */}
    </Suspense>
  );
};
