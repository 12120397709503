import ky from 'ky';
import { generateUrl } from '../utilities/generate-url';
import { createJsonApiClient } from '../utilities/create-json-api-client';

export const apiClient = ky.create({
  prefixUrl: generateUrl(),
  retry: 0, // We let react-query do the retries
  hooks: {
    beforeRequest: [
      request => {
        // const authorization = localStorage.getItem('authorization');
        // if (authorization) {
        //   request.headers.set('Authorization', authorization);
        // }
      },
    ],
    beforeError: [
      // async (error) => {
      //   const response = await error.response.json();
      //   return {
      //     ...error,
      //     data: response,
      //   };
      // },
    ],
    afterResponse: [
      // (_request, _options, response) => {
      //   if ([401, 451].includes(response.status)) {
      //     // Logout?
      //   }
      //   return response;
      // },
    ],
  },
});

export const jsonApiClient = createJsonApiClient(apiClient);
