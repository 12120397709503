import { QueryClient, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getCategories } from './categories-api';
import { Category } from '../@types';

export const categoryKeys = {
  all: (): QueryKey => ['Category'],
};

export const categoryQuery: UseQueryOptions<Category[]> = { queryKey: categoryKeys.all(), queryFn: ({ signal }) => getCategories(signal) };

export const prefetchCategory = async (queryClient: QueryClient): Promise<void> => {
  await queryClient.prefetchQuery(categoryQuery);
};

export const useCategories = () => {
  const query = useQuery(categoryQuery);

  return { query };
};
