import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScoreTypeForm from '../components/score-type-form';
import ScoreTypeSelector from '../components/score-type-selector';
import { useScoreTypes } from '../model/score-type-queries';

const ScoreTypes: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const { score_type_id } = useParams<{ score_type_id: string }>();
  const { query: scoreTypeQuery } = useScoreTypes();
  const toggleCollapse = () => {
    setMenuOpen(prev => !prev);
  };

  const selectedScoreType = useMemo(() => {
    return scoreTypeQuery.data?.find(f => f.id === score_type_id);
  }, [scoreTypeQuery.data, score_type_id]);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <ScoreTypeSelector isMenuOpen={isMenuOpen} />
      <div>
        <Button type="primary" onClick={toggleCollapse} style={{ marginTop: 2, borderRadius: '0px 8px 8px 0px', width: '32px' }}>
          <FontAwesomeIcon style={{ margin: '0 auto', transform: 'translateX(-8px)' }} icon={isMenuOpen ? faArrowLeft : faArrowRight} />
        </Button>
      </div>
      <ScoreTypeForm key={selectedScoreType?.id} selectedScoreType={selectedScoreType} />
    </div>
  );
};

export default ScoreTypes;
