import { Card } from 'antd';
import { useUserInfo } from '../model/queries';

const User = () => {
  const { query } = useUserInfo();

  return (
    <>
      <br />
      <Card>
        {query.isLoading && <>Loading...</>}
        {query.isError && <>Error!</>}
        {query.isPaused && <>Paused...</>}
        {query.isFetching && <>Fetching...</>}
        {query.isSuccess && (
          <>
            <b>User ID: {query.data.clientPrincipal?.userId}</b>
            <p>User Details: {query.data.clientPrincipal?.userDetails}</p>
            <p>User Roles: {JSON.stringify(query.data.clientPrincipal?.userRoles)}</p>
            <p>Identity Provider: {query.data.clientPrincipal?.identityProvider}</p>
            <p>Claims: {JSON.stringify(query.data.clientPrincipal?.claims)}</p>
          </>
        )}
      </Card>
    </>
  );
};

export default User;
