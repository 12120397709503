import { Component, ErrorInfo } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

interface Props {
  appInsights: ApplicationInsights;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component<Props> {
  public state: State = {
    hasError: false,
    error: undefined,
    errorInfo: undefined,
  };

  public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): State {
    return { hasError: true, error, errorInfo };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { appInsights } = this.props;
    appInsights.trackException({
      exception: error,
      properties: { componentStack: errorInfo.componentStack },
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Sorry.. er is iets fout gegaan</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
        </>
      );
    }

    return this.props.children;
  }
}
