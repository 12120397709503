import { Form, Switch } from 'antd';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

const ScoreTypeSwitches: FC = () => {
  const { location_id } = useParams<{ location_id: string }>();

  return (
    <div className={styles.scoreTypesContainer}>
      <Form.List name={['locationScoreGroupScoreTypes']}>
        {fields => (
          <>
            {fields.map(({ key, name }) => (
              <div className={styles.scoreTypeItem} key={key}>
                <div className={styles.switchContainer}>
                  <Form.Item name={[name, 'scoreTypeId']} hidden />
                  <Form.Item valuePropName="checked" name={[name, 'value']}>
                    <Switch className={styles.switch} disabled={!location_id} />
                  </Form.Item>
                </div>
                <div className={styles.nameContainer}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const itemName = getFieldValue(['locationScoreGroupScoreTypes', name, 'name']);
                      return <span>{itemName}</span>;
                    }}
                  </Form.Item>
                </div>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ScoreTypeSwitches;
