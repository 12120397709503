export const generateUrl = (path?: string, appendApi = true): string => {
  const strippedPath = path?.replace(/^\/+/, '');
  let baseUrl = location.origin + (appendApi ? '/api' : '');

  if (location.origin === 'http://localhost:4280') {
    baseUrl = 'http://localhost:7242' + (appendApi ? '/api' : '');
  }

  return [baseUrl, strippedPath].filter(Boolean).join('/');
};

export const generateUrlGenerator =
  (basePath: string, appendApi?: boolean) =>
  (path?: string): string =>
    generateUrl([basePath, path].filter(Boolean).join('/'), appendApi);
