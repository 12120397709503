import { QueryClient, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getAnimals } from './animal-api';
import { Animal } from '@/modules/score-type/@types';

export const animalKeys = {
  all: (): QueryKey => ['Animal'],
  detail: (location_id: string): QueryKey => [...animalKeys.all(), location_id],
};
export const animalsQuery = (location_id: string): UseQueryOptions<Animal[]> => ({
  queryKey: animalKeys.detail(location_id),
  queryFn: ({ signal }) => getAnimals(location_id, signal),
});

// This function is callable from anywhere and it enables you to already have the cow available in cache
export const prefetchAnimals = async (queryClient: QueryClient, location_id: string): Promise<void> => {
  await queryClient.prefetchQuery(animalsQuery(location_id));
};

export const useAnimals = (location_id: string) => {
  const query = useQuery(animalsQuery(location_id));

  return { query };
};
