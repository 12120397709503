import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Empty } from 'antd';
import SideMenuAccordion from '../components/generate-side-menu';
import GenerateScoreGroupForm from '../components/generate-score-group-form';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

const ScoreGroups: FC = () => {
  const { score_group_id } = useParams();
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);

  const toggleCollapse = () => {
    setIsCollapseOpen(prev => !prev);
  };

  return (
    <div className={styles.container}>
      <div className={styles.menuSection}>
        <SideMenuAccordion isCollapseOpen={isCollapseOpen} />
        <div className={styles.floatingButton}>
          <Button type="primary" onClick={toggleCollapse} style={{ marginTop: 2, borderRadius: '0px 8px 8px 0px', width: '32px' }}>
            <FontAwesomeIcon style={{ margin: '0 auto', transform: 'translateX(-8px)' }} icon={isCollapseOpen ? faArrowLeft : faArrowRight} />
          </Button>
        </div>
        <div className={styles.contentContainer}>
          {score_group_id ? <GenerateScoreGroupForm /> : <Empty className={styles.emptyStyle} description={<span>Geen settings gevonden</span>} />}
        </div>
      </div>
    </div>
  );
};

export default ScoreGroups;
