import { QueryClient, QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getScoreTypeSettings, postScoreTypeSettings } from './score-type-setting-api';
import { LocationScoreTypeSetting } from '../@types';
import { message } from 'antd';
import { useCallback } from 'react';

export const scoreTypeSettingsKeys = {
  all: (): QueryKey => ['ScoreTypeSetting'],
  detail: (scoreTypeId: string, locationId?: string): QueryKey => [...scoreTypeSettingsKeys.all(), scoreTypeId, locationId],
};

export const scoreTypeSettingsQuery = (scoreTypeId: string, locationId?: string): UseQueryOptions<LocationScoreTypeSetting> => ({
  queryKey: scoreTypeSettingsKeys.detail(scoreTypeId, locationId),
  queryFn: ({ signal }) => getScoreTypeSettings(scoreTypeId, locationId, signal),
});

export const scoreTypeSettingsMutation = (
  queryClient: QueryClient,
  scoreTypeId: string,
  locationId?: string
): UseMutationOptions<LocationScoreTypeSetting, any, LocationScoreTypeSetting, any> => ({
  mutationKey: scoreTypeSettingsKeys.detail(scoreTypeId, locationId),
  mutationFn: scoreTypeSetting => postScoreTypeSettings({ ...scoreTypeSetting }),
  onMutate: async scoreTypeSettings => {
    // Verwijderen van vorige mutations die in de cache zitten, met dezelfde key en nog niet uitgevoerd zijn. We willen alleen de laatste uitvoeren.
    // Onderstaande lijkt niet mogelijk
    // const mutationCache = queryClient.getMutationCache();
    // const sameKeyMutations = queryClient.getMutationCache().findAll({
    //   mutationKey: scoreTypeSettingsKeys.detail(id),
    //   exact: true,
    //   fetching: true,
    // });
    // if (sameKeyMutations.length > 1) {
    //   const highestId = Math.max(...sameKeyMutations.map(mutation => mutation.mutationId));

    //   sameKeyMutations.forEach(mutation => {
    //     if (mutation.mutationId !== highestId) {
    //       mutationCache.remove(mutation);
    //     }
    //   });
    // }

    // Cancel current queries for the user
    await queryClient.cancelQueries({ queryKey: scoreTypeSettingsKeys.detail(scoreTypeId, locationId) });
    const previousData = queryClient.getQueryData(scoreTypeSettingsKeys.detail(scoreTypeId, locationId));

    // Optimistic edit of the user
    queryClient.setQueryData(scoreTypeSettingsKeys.detail(scoreTypeId, locationId), () => scoreTypeSettings);

    // Return context with the optimistic todo
    return { optimisticData: scoreTypeSettings, previousData };
  },
  onSuccess: (result, _scoreTypeSettings, _context) => {
    // Replace optimistic user with the actual result (todo: if result is precisely the same as context.optimisticData, then don't replace and don't trigger rerender)
    // queryClient.setQueryData(scoreTypeSettingsKeys.detail(scoreTypeId, locationId), () => result);
    message.success('Scoretype instelling is opgeslagen');
    console.log('success');
  },
  onError: (error, _scoreTypeSettings, context) => {
    console.error(error);
    message.error(error);
    // Reset scoreTypeSettings to previousData
    queryClient.setQueryData(scoreTypeSettingsKeys.detail(scoreTypeId, locationId), () => context.previousData);
  },
  onSettled: () => {
    console.log('settled.');
    // We don't need to invalidate the query because we used the result in the onSucces.
    // Uncomment below if you do want to refetch though.
    queryClient.invalidateQueries({ queryKey: scoreTypeSettingsKeys.detail(scoreTypeId, locationId) });
  },
});

// This function is callable from anywhere and it enables you to already have the score type setting available in cache
export const prefetchScoreType = async (queryClient: QueryClient, scoreTypeId: string, locationId?: string): Promise<void> => {
  await queryClient.prefetchQuery(scoreTypeSettingsQuery(scoreTypeId, locationId));
};

export const useScoreTypeSettings = (scoreTypeId: string, locationId?: string) => {
  const queryClient = useQueryClient();
  const query = useQuery(scoreTypeSettingsQuery(scoreTypeId, locationId));
  const update = useMutation(scoreTypeSettingsMutation(queryClient, scoreTypeId, locationId));
  const mutate: typeof update.mutate = useCallback((variables, options) => update.mutate({ ...variables }, options), [update]);
  return { query, update: { ...update, mutate } };
};
