import { App } from 'app';
import { createRoot } from 'react-dom/client';

import './shared/utilities/i18n';
import 'styles/global.scss';
import { Provider } from 'jotai';
import { ErrorBoundary } from './shared/components';
import { StrictMode } from 'react';
import { ServiceWorkerProvider } from '@/modules/service-worker/providers/service-worker-provider';
import { PersistingQueryClientProvider } from '@/shared/components/persisting-query-client-provider';

(async () => {
  if (import.meta.env.VITE_API_MOCK === 'true') {
    const setupMocking = await import('./shared/utilities/setupMocking');
    await setupMocking.default();
    console.info('MSW mocking is enabled');
  }

  const container = document.getElementById('root');
  const root = createRoot(container as Element);
  root.render(
    <StrictMode>
      <ServiceWorkerProvider>
        <PersistingQueryClientProvider>
          <Provider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Provider>
        </PersistingQueryClientProvider>
      </ServiceWorkerProvider>
    </StrictMode>
  );
})();
