import { Typography, Button } from 'antd';
import { FC } from 'react';

const { Title } = Typography;

const NotFound: FC = () => (
  <div style={{ padding: '50px' }}>
    <Title level={3}>Pagina niet gevonden</Title>
    <Button type="primary" onClick={() => window.history.back()}>
      Terug naar vorige pagina
    </Button>
  </div>
);

export default NotFound;
