import { FC, lazy } from 'react';
import { Navigate, RouteObject, RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import { BaseLayout } from 'shared/components';
import ScoreTypes from '@/modules/score-type/pages';
import ScoreGroups from '@/modules/score-group/pages';
import User from '@/modules/user/pages/user';
import { useUserInfo } from '@/modules/user/model/queries';
import { generateUrl } from '@/shared/utilities/generate-url';
import { useLocations } from '@/modules/location-selector/model/location-queries';
import { useQueryClient } from '@tanstack/react-query';
import Settings from '@/modules/settings/pages';
import ComingSoon from '@/shared/components/error-boundary/comingSoon';
import NotFound from '@/shared/components/error-boundary/notFound';

const LocationSelector = lazy(() => import('@/modules/location-selector/pages'));
const ScoreApp = lazy(() => import('@/modules/score-app/pages'));
const Report = lazy(() => import('@/modules/report/pages/report'));

export const AppRouter: FC = () => {
  const { query, isFarmer } = useUserInfo();
  const { query: locationsQuery } = useLocations();
  const queryClient = useQueryClient();

  if (query.isLoading) {
    return null;
  } else if (query.isError) {
    return <>Er is iets mis gegaan bij het ophalen van de gebruiker.</>;
  } else if (query.isSuccess && query.data.clientPrincipal === null) {
    // TODO: make sure someone is not just offline?
    const clearAndRedirect = async () => {
      if (!import.meta.env.DEV) {
        // Clear query from cache (otherwise react query will return null again on the next query)
        queryClient.setDefaultOptions({ queries: { enabled: false } });
        queryClient.removeQueries();

        // Remove cached queries, retain cached mutations
        const cache = window.localStorage.getItem('REACT_QUERY_OFFLINE_CACHE');
        if (cache != null) {
          const data = JSON.parse(cache);
          data.clientState.queries = [];
          const cachedMutations = JSON.stringify(data);
          window.localStorage.setItem('REACT_QUERY_OFFLINE_CACHE', cachedMutations);
        }

        // Redirect user to login.
        window.open(generateUrl(`.auth/login/aadb2c?post_login_redirect_uri=${window.location.href}`, false), '_self');
      }
    };

    clearAndRedirect();

    return null;
  }

  const routes: RouteObject[] = [
    {
      element: <BaseLayout />,

      children: [
        { element: <ScoreTypes />, path: 'instellingen/scoretype-settings/:score_type_id?' },
        { element: <ScoreGroups />, path: 'instellingen/scoregroup-settings/:category_id?/score-group?/:score_group_id?' },
        {
          element: isFarmer ? <Navigate to={`bedrijfsbezoek/${locationsQuery.data?.[0]?.id}/report/Vastzetlijst`} /> : <Navigate to="/bedrijven/overzicht" />,
          path: '',
        },
        { element: <LocationSelector />, path: '/bedrijven/overzicht' },
        {
          path: 'bedrijfsbezoek/:location_id',
          children: [
            { element: <ScoreApp />, path: 'score/:animal_id?' },
            { element: <Report />, path: 'report/:reportName' },
            { element: <ComingSoon />, path: 'data-uitwisselingen' }, // not yet available
          ],
        },
        {
          path: 'cockpit-bedrijf/:location_id',
          children: [
            { element: <Report />, path: 'report/:reportName' },
            { element: <ComingSoon />, path: 'report/DashboardBedrijf' }, // not yet available
            { element: <Report />, path: 'report/:reportName' },
            { element: <ComingSoon />, path: 'report/GezondheidKoeien' }, // not yet available
            { element: <ComingSoon />, path: 'report/OpstartLactatie' }, // not yet available
            { element: <ComingSoon />, path: 'report/Jongvee' }, // not yet available
          ],
        },
        {
          path: 'dierkaart/:location_id',
          children: [{ element: <Report />, path: 'report/:reportName' }],
        },
        {
          path: 'instellingen/:location_id',
          children: [
            { element: <ScoreTypes />, path: 'scoretype-settings/:score_type_id?' },
            { element: <ScoreGroups />, path: 'scoregroup-settings/:category_id?/score-group?/:score_group_id?' },
            { element: <Settings />, path: 'kaders' },
          ],
        },
        { element: <User />, path: '/user' },
        { element: <ComingSoon />, path: '/exports/:location_id' }, // not yet available
        { element: <ComingSoon />, path: '/exports/*' }, // not yet available
        { element: <ComingSoon />, path: '/documenten' }, // not yet available
        { element: <ComingSoon />, path: '/bedrijven/beheer' }, // not yet available
        { element: <NotFound />, path: '*' }, // 404 catch all
      ],
    },
  ];
  const router = createBrowserRouter(routes);

  return (
    // <ErrorBoundary FallbackComponent={Fallback} onReset={e => {}}>
    <RouterProvider router={router} />
    // </ErrorBoundary>
  );
};
