import { jsonApiClient } from 'shared/model';
import { ScoreGroupSetting } from '../@types';

const api = jsonApiClient('AllScoreGroupSetting');

export const getAllScoreGroupSettings = (scoreGroupId: string, locationId?: string, signal?: AbortSignal) =>
  api.get<ScoreGroupSetting>(locationId ? `${scoreGroupId}/${locationId}` : `${scoreGroupId}`, { signal });

export const getScoreGroupSettings = (scoreGroupId: string, locationId?: string, signal?: AbortSignal) =>
  api.get<ScoreGroupSetting>(locationId ? `${scoreGroupId}/${locationId}` : `${scoreGroupId}`, { signal });

export const postScoreGroupSettings = (scoreGroupId: string, locationId?: string, scoreGroupSetting?: ScoreGroupSetting) =>
  api.post<ScoreGroupSetting>(locationId ? `${scoreGroupId}/${locationId}` : `${scoreGroupId}`, scoreGroupSetting);
