import { QueryClient, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getSettings } from './settings-api';
import { Setting } from '../@types';

export const settingsKeys = {
  all: (): QueryKey => ['Settings'],
  detail: (id: string): QueryKey => [...settingsKeys.all(), id],
};

export const settingsQuery: UseQueryOptions<Setting[]> = { queryKey: settingsKeys.all(), queryFn: ({ signal }) => getSettings(signal) };

// This function is callable from anywhere and it enables you to already have the score type available in cache
export const prefetchSettings = async (queryClient: QueryClient): Promise<void> => {
  await queryClient.prefetchQuery(settingsQuery);
};

export const useSettings = () => {
  const query = useQuery(settingsQuery);
  return { query };
};

export const useScoreGroup = () => {
  const query = useQuery(settingsQuery);

  return { query };
};
