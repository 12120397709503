import { QueryClient, QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getLocationSettings, postLocationSettings } from './settings-api';
import { LocationSetting } from '../@types';
import { message } from 'antd';

export const locationSettingsKeys = {
  all: (): QueryKey => ['LocationSetting'],
  detail: (id: string): QueryKey => [...locationSettingsKeys.all(), id],
};

export const locationSettingsQuery = (id: string): UseQueryOptions<LocationSetting[]> => ({
  queryKey: locationSettingsKeys.detail(id),
  queryFn: ({ signal }) => getLocationSettings(id, signal),
});

export const locationSettingsMutation = (queryClient: QueryClient, id: string): UseMutationOptions<LocationSetting[], any, LocationSetting[], any> => ({
  mutationKey: locationSettingsKeys.detail(id),
  mutationFn: locationSettings => postLocationSettings(locationSettings),
  onMutate: async locationSettings => {
    // Verwijderen van vorige mutations die in de cache zitten, met dezelfde key en nog niet uitgevoerd zijn. We willen alleen de laatste uitvoeren.
    // Onderstaande lijkt niet mogelijk
    // const mutationCache = queryClient.getMutationCache();
    // const sameKeyMutations = queryClient.getMutationCache().findAll({
    //   mutationKey: locationSettingsKeys.detail(id),
    //   exact: true,
    //   fetching: true,
    // });
    // if (sameKeyMutations.length > 1) {
    //   const highestId = Math.max(...sameKeyMutations.map(mutation => mutation.mutationId));

    //   sameKeyMutations.forEach(mutation => {
    //     if (mutation.mutationId !== highestId) {
    //       mutationCache.remove(mutation);
    //     }
    //   });
    // }

    // Cancel current queries for the locationSettings
    await queryClient.cancelQueries({ queryKey: locationSettingsKeys.detail(id) });
    const previousData = queryClient.getQueryData(locationSettingsKeys.detail(id));

    // Optimistic edit of the locationSettings
    queryClient.setQueryData(locationSettingsKeys.detail(id), () => locationSettings);

    // message.info('LocationSetting is alvast lokaal opgeslagen.');

    // Return context with the optimistic todo
    return { optimisticData: locationSettings, previousData };
  },
  onSuccess: (result, _locationSettings, _context) => {
    console.log(_locationSettings);
    // Replace optimistic locationSettings with the actual result (todo: if result is precisely the same as context.optimisticData, then don't replace and don't trigger rerender)
    // queryClient.setQueryData(locationSettingsKeys.detail(id), () => result);
    message.success('LocationSettings daadwerkelijk online opgeslagen');
    console.log('success');
  },
  onError: (error, _locationSettings, context) => {
    console.error(error);
    message.error(error);
    // Reset LocationSetting to previousData
    queryClient.setQueryData(locationSettingsKeys.detail(id), () => context.previousData);
  },
  onSettled: () => {
    console.log('settled.');
    // We don't need to invalidate the query because we used the result in the onSucces.
    // Uncomment below if you do want to refetch though.
    queryClient.invalidateQueries({ queryKey: locationSettingsKeys.detail(id) });
  },
});

// This function is callable from anywhere and it enables you to already have the score type available in cache
export const prefetchLocationSettings = async (queryClient: QueryClient, id: string): Promise<void> => {
  await queryClient.prefetchQuery(locationSettingsQuery(id));
};

export const useLocationSettings = (locationId: string) => {
  const queryClient = useQueryClient();
  const query = useQuery(locationSettingsQuery(locationId));
  const update = useMutation(locationSettingsMutation(queryClient, locationId));

  return { query, update };
};
